import cx from 'classnames';
import React from 'react';

import { getEstimatedProductMatrixHeight } from '../../../logic/sizeCharts';
import { useMatrixLayout } from '../../../utils/hooks/matrix/useMatrixLayout';
import { useMatrixPresets } from '../../../utils/hooks/matrix/useMatrixPresets';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { LazyRender } from '../../various/LazyRender';
import { Matrix } from '../../various/Matrix';
import { ProductDetailsModalContext } from '../product-details/ProductDetailsModal';

import {
  MatrixDistributionContextProvider,
  MatrixPrepackContextProvider,
  MatrixQuantitiesContextProvider,
  MatrixSettingsContext,
  MatrixVariantsContext,
  useMatrixColumnsContext,
} from './context';
import { MatrixOverflow } from './MatrixOverflow';
import { MatrixVariantInfo } from './MatrixVariantInfo';
import styles from './ProductMatrixSection.module.scss';

const VIEWPORT_HEIGHT_DIVIDER = 2;

export const MatrixLazyRender = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const { isInDetailsModal } = React.useContext(ProductDetailsModalContext);
  const { isMobile, isReadOnly, isLookbook } = React.useContext(MatrixSettingsContext);
  const { variants } = React.useContext(MatrixVariantsContext);
  const { canPinColumns } = useMatrixColumnsContext();
  const { hasMultipleRows } = useMatrixLayout();
  const { hasPresetsControls } = useMatrixPresets();

  const hasVisiblePresetControls = hasPresetsControls && isMobile;

  const estimatedHeight = getEstimatedProductMatrixHeight({
    hasControls: hasVisiblePresetControls || canPinColumns,
    hasSummaries: !isLookbook && hasMultipleRows,
    isMobile,
    variants,
  });

  if (isEmpty(variants)) {
    return null;
  }

  const [firstVariant] = variants;

  return (
    <div
      ref={ref}
      className={cx(styles.variantMatrix, {
        [styles.matrixDisabled]: isReadOnly,
      })}
    >
      {isMobile && isDefined(firstVariant) && <MatrixVariantInfo variant={firstVariant} />}
      <div className={styles.items}>
        <div className={styles.form}>
          <MatrixPrepackContextProvider>
            <MatrixDistributionContextProvider>
              <MatrixQuantitiesContextProvider>
                <LazyRender
                  forceVisible={isInDetailsModal}
                  estimatedHeight={estimatedHeight}
                  wrapperClassName={styles.lazyWrapper}
                  rootMargin={`${window.screen.height / VIEWPORT_HEIGHT_DIVIDER}px 0px`}
                >
                  <MatrixOverflow>
                    <Matrix />
                  </MatrixOverflow>
                </LazyRender>
              </MatrixQuantitiesContextProvider>
            </MatrixDistributionContextProvider>
          </MatrixPrepackContextProvider>
        </div>
      </div>
    </div>
  );
};
