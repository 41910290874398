import { Position } from '@typings';
import React from 'react';

import { MatrixNavigationContext } from '../../../components/products/ProductMatrix/context';
import { isDefined } from '../../is';
import { isArrowKey } from '../../keys';
import { getIsPositionWithinRanges, getNewLocationKeyboardNav, selectedRange } from '../../matrix';
import { useEventListener } from '../useEventListener';

import { useNavigationRange } from './useNavigationRange';

export const useMatrixSelect = <T extends HTMLElement>(cellPosition: Position, ref: React.RefObject<T>) => {
  const { selectedPosition, setSelectedPosition, isSelecting, hasActiveElement } = React.useContext(MatrixNavigationContext);
  const { getColumnNavigationRange, getRowNavigationRange } = useNavigationRange();

  const isSelected = React.useMemo(() => {
    if (!hasActiveElement) {
      return false;
    }

    const { start, end } = selectedPosition;
    const yInSelectedRange = selectedRange(start.y, end.y).some(y => cellPosition.y === y);
    const xInSelectedRange = selectedRange(start.x, end.x).some(x => cellPosition.x === x);

    return xInSelectedRange && yInSelectedRange && hasActiveElement;
  }, [selectedPosition, cellPosition, hasActiveElement]);

  const handleMouseOver = () => {
    setSelectedPosition(pos => ({ ...pos, end: cellPosition }));
  };

  const handleMouseDown = () => {
    setSelectedPosition({ end: cellPosition, start: cellPosition });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const isShiftArrowCombo = isArrowKey(event.key) && event.shiftKey;

    if (!isShiftArrowCombo) {
      return;
    }

    event.preventDefault();

    const newLocation = getNewLocationKeyboardNav(event, selectedPosition.end);

    if (!isDefined(newLocation)) {
      return;
    }

    const rowRange = getRowNavigationRange(newLocation.y);
    const columnRange = getColumnNavigationRange(newLocation.x);

    if (getIsPositionWithinRanges(newLocation, rowRange, columnRange)) {
      setSelectedPosition(pos => ({ ...pos, end: newLocation }));
    }
  };

  useEventListener({
    element: ref,
    eventName: 'mouseover',
    handler: handleMouseOver,
    isDisabled: !isSelecting,
  });

  useEventListener({
    element: ref,
    eventName: 'mousedown',
    handler: handleMouseDown,
  });

  useEventListener({
    element: ref,
    eventName: 'keydown',
    handler: handleKeyDown,
    isDisabled: !isSelected,
  });

  return {
    isSelected,
  };
};
