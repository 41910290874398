import { Id, Product } from '@typings';
import { pipe } from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';

import { pushEvent, setPrepacksRequest } from '../../../../ducks';
import { calculateOptimisticItems, getPrepacksFromMap, getPrepacksRequestData, updatePrepackQuantity } from '../../../../logic/presets';
import { getUnitSwitcherName } from '../../../../logic/unitsSwitch';
import { MatrixTrackingEvent } from '../../../../utils/analytics/events';
import { useSelectedUnit } from '../../../../utils/hooks/unitSwitcher/useSelectedUnit';
import { isDefined } from '../../../../utils/is';
import { MatrixDeliveryWindowContext, MatrixPrepackContext } from '../../../products/ProductMatrix/context';
import { NoStockCell } from '../MatrixCells/NoStockCell';
import { PrepackSwitchCell } from '../MatrixCells/PrepackSwitchCell';
import { QuantityInputCell } from '../MatrixCells/QuantityInputCell';

interface Props {
  variant: Product.Standard;
  rowSpan?: number;
  rowIndex: number;
  prepacksQuantities: Record<string, number>;
  isCancelled: boolean;
}

export const PrepacksBodyCells = ({ variant, rowSpan, rowIndex, prepacksQuantities, isCancelled }: Props) => {
  const switchName = getUnitSwitcherName(variant.tableMappings ?? {});
  const selectedUnit = useSelectedUnit({ switchName });
  const dispatch = useDispatch();
  const {
    activePrepacks,
    prepacksMap,
    shouldHidePrepackSwitchColumn,
    uniquePrepacks,
    variantsWithDisabledPrepacks,
    variantsWithEnforcedPrepacks,
    visiblePrepacksLength,
  } = React.useContext(MatrixPrepackContext);
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);

  const variantId = variant.variant;
  const productId = variant.product;
  const variantItems = variant.items;

  const isChecked = activePrepacks[variantId];

  const isSwitchDisabled = variantsWithEnforcedPrepacks.includes(variantId) || variantsWithDisabledPrepacks.includes(variantId);

  const handlePrepackChange = React.useCallback(
    (itemId: Id, value: number) => {
      const prepacks = pipe(getPrepacksFromMap, updatePrepackQuantity(value, itemId))(prepacksMap, variantId);

      const items = calculateOptimisticItems(variantItems, deliveryWindowId)(prepacks);

      dispatch(pushEvent({ event: MatrixTrackingEvent.PREPACKS_USED }));
      dispatch(
        setPrepacksRequest({
          data: {
            deliveryWindow: deliveryWindowId,
            prepacks: getPrepacksRequestData(prepacks, selectedUnit),
            variant: variantId,
          },
          items,
          product: productId,
        }),
      );
    },
    [deliveryWindowId, prepacksMap, productId, variantItems, variantId, dispatch, selectedUnit],
  );

  const hasVisiblePrepacks = visiblePrepacksLength > 0;
  const availablePrepacks = prepacksMap[variantId]?.prepacks.map(prepack => prepack.id);
  const is2DSpacingCell = isDefined(rowSpan) && rowIndex === 1;
  const is2DSkipableRow = isDefined(rowSpan) && rowIndex > 1;
  if (!hasVisiblePrepacks || is2DSkipableRow) {
    return null;
  }

  if (is2DSpacingCell) {
    return (
      <>
        {!shouldHidePrepackSwitchColumn && <td key="spacingCell" rowSpan={rowSpan - 1} />}
        {uniquePrepacks.map(prepack => (
          <td key={prepack.id} rowSpan={rowSpan - 1} />
        ))}
      </>
    );
  }

  return (
    <>
      {!shouldHidePrepackSwitchColumn && (
        <PrepackSwitchCell variantId={variantId} isDisabled={isSwitchDisabled} position={{ x: -1, y: rowIndex }} />
      )}
      {uniquePrepacks.map((prepack, idx) => {
        const quantity = prepacksQuantities[prepack.id] ?? 0;
        const isAvailable = availablePrepacks?.includes(prepack.id) ?? false;
        const position = { x: idx, y: rowIndex };

        if (!isAvailable) {
          return <NoStockCell position={position} key={prepack.id} />;
        }

        return (
          <QuantityInputCell
            key={prepack.id}
            isDisabled={!isChecked || isCancelled}
            isPrepack
            itemId={prepack.id}
            value={quantity}
            onBlur={handlePrepackChange}
            defaultPlaceholder="0"
            position={position}
          />
        );
      })}
    </>
  );
};
