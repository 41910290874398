import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getHasAnyActiveFilters, getProductFilters, resetFilters, updateFilters } from '../../../ducks/';
import { isEmpty } from '../../../utils/isEmpty';
import { RevealPopup } from '../RevealPopup/RevealPopup';

import { AppliedFilters } from './AppliedFilters';
import { ClearFilterButton } from './ClearFilterButton';
import styles from './FiltersBar.module.scss';

const OVERFLOW_OFFSET = 20;

export const FiltersBar = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const hasAnyActiveFilters = useSelector(getHasAnyActiveFilters);
  const filters = useSelector(getProductFilters);

  const searchFilterName = (
    <>
      <span className={styles.searchFilterLabel}>{t('common:search')}:&nbsp;</span>
      {filters.search}
    </>
  );
  const containerClasses = cx({
    [styles.container]: true,
    [styles.filtersVisible]: hasAnyActiveFilters,
  });

  const clearSearchFilter = React.useCallback(() => {
    dispatch(updateFilters({ filters: { search: '' }, preserveCurrent: true }));
  }, [dispatch]);

  const clearFilters = React.useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <div className={containerClasses}>
      <RevealPopup overflowOffset={OVERFLOW_OFFSET}>
        <AppliedFilters filters={filters} />
        {!isEmpty(filters.search) && (
          <ClearFilterButton
            appearance="clearSearchFilter"
            className={styles.clearFilterButton}
            name={searchFilterName}
            onClick={clearSearchFilter}
          />
        )}
        {hasAnyActiveFilters && (
          <ClearFilterButton
            name={t('common:clear_all')}
            appearance="clearAll"
            className={styles.clearFilterButton}
            onClick={clearFilters}
          />
        )}
      </RevealPopup>
    </div>
  );
};
