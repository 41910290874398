import React from 'react';

import { useProductConfiguration } from '../../../../utils/hooks/matrix/useProductConfiguration';
import { isDefined } from '../../../../utils/is';
import { MatrixSettingsContext, MatrixVariantsContext } from '../../../products/ProductMatrix/context';
import { RegularHeaderCell } from '../MatrixCells';
import { AttributeName } from '../MatrixContents/Attribute';

import styles from './MatrixHead.module.scss';

export const VariantHeadCells = () => {
  const { productConfiguration } = React.useContext(MatrixSettingsContext);
  const { variants } = React.useContext(MatrixVariantsContext);
  const { configurationAttributeTypes } = useProductConfiguration({ productConfiguration, variants });

  if (!isDefined(productConfiguration)) {
    return <th />;
  }

  return (
    <>
      {configurationAttributeTypes.map(({ attributesDetails, name }) => (
        <RegularHeaderCell key={name} className={styles.headAttributeCell}>
          <AttributeName name={name} attributesDetails={attributesDetails} />
        </RegularHeaderCell>
      ))}
    </>
  );
};
