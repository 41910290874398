import * as amplitude from '@amplitude/analytics-browser';
import { Amplitude } from '@typings/internal/amplitude';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsUserAdmin, getUserAccess } from '../../ducks';

export const useAmplitude = () => {
  const isUserAdmin = useSelector(getIsUserAdmin);
  const userAccess = useSelector(getUserAccess);

  const userType = isUserAdmin ? 'admin' : userAccess;

  const trackEvent = React.useCallback(
    (event: Amplitude.AnyEvent) => {
      amplitude.identify(new amplitude.Identify().set('userType', userType));
      amplitude.track(event.name, 'properties' in event ? event.properties : undefined);
    },
    [userType],
  );

  const resetUser = React.useCallback(() => {
    amplitude.reset();
  }, []);

  return {
    resetUser,
    trackEvent,
  };
};
