import { Id, Prepack, Preset } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getOrderDetails } from '../../../../ducks';
import { getVariantsIdsWithPrepacksAdded } from '../../../../logic/presets';
import { useMatrixPresets } from '../../../../utils/hooks/matrix/useMatrixPresets';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import { MatrixDeliveryWindowContext } from './MatrixDeliveryWindowContext';
import { MatrixSettingsContext } from './MatrixSettingsContext';
import { MatrixVariantsContext } from './MatrixVariantsContext';

interface Context {
  shouldHidePrepackSwitch: boolean;
  shouldHidePrepackSwitchColumn: boolean;
  uniquePrepacks: Preset[];
  prepacksMap: Prepack.Map;
  setPrepacksState: (variantId: Id, isActive: boolean) => void;
  activePrepacks: Record<string, boolean>;
  variantsWithDisabledPrepacks: string[];
  variantsWithEnforcedPrepacks: string[];
  visiblePrepacksLength: number;
}

const initialContext: Context = {
  activePrepacks: {},
  prepacksMap: {},
  setPrepacksState: (_: Id, __: boolean) => null,
  shouldHidePrepackSwitch: false,
  shouldHidePrepackSwitchColumn: false,
  uniquePrepacks: [],
  variantsWithDisabledPrepacks: [],
  variantsWithEnforcedPrepacks: [],
  visiblePrepacksLength: 0,
};

export const MatrixPrepackContext = React.createContext<Context>(initialContext);

export const MatrixPrepackContextProvider = ({ children }: React.WithChildren) => {
  const { variants } = React.useContext(MatrixVariantsContext);
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);

  const { isMobile } = React.useContext(MatrixSettingsContext);
  const { prepacksMap, uniquePrepacks, shouldHidePrepackSwitch } = useMatrixPresets();
  const orderDetails = useSelector(getOrderDetails);

  const variantsWithDisabledPrepacks = React.useMemo(
    () =>
      Object.entries(prepacksMap)
        .filter(([_, prepack]) => isEmpty(prepack.prepacks))
        .map(([key]) => key),
    [prepacksMap],
  );

  const variantsWithEnforcedPrepacks = React.useMemo(
    () =>
      Object.entries(prepacksMap)
        .filter(([_, prepack]) => prepack.isEnforced)
        .map(([key]) => key),
    [prepacksMap],
  );

  const initialActivePrepacks = React.useMemo(() => {
    const variantsWithQuantities = getVariantsIdsWithPrepacksAdded(variants, deliveryWindowId, orderDetails);

    return [...variantsWithQuantities, ...variantsWithEnforcedPrepacks].reduce(
      (acc, variantId) => ({
        ...acc,
        [variantId]: true,
      }),
      {},
    );
  }, []);

  const [activePrepacks, setActivePrepacks] = React.useState<Record<string, boolean>>(initialActivePrepacks);

  const setPrepacksState = React.useCallback(
    (variantId: Id, isActive: boolean) => {
      if (variantsWithEnforcedPrepacks.includes(variantId)) {
        return;
      }

      setActivePrepacks(currentPrepacks => ({
        ...currentPrepacks,
        [variantId]: isActive,
      }));
    },
    [setActivePrepacks, variantsWithEnforcedPrepacks],
  );

  const firstVariantId = variants[0]?.variant;
  const isActivePrepack = isDefined(firstVariantId) && activePrepacks[firstVariantId];
  const visiblePrepacksLength = isMobile && !isActivePrepack ? 0 : uniquePrepacks.length;

  return (
    <MatrixPrepackContext.Provider
      value={{
        activePrepacks,
        prepacksMap,
        setPrepacksState,
        shouldHidePrepackSwitch,
        shouldHidePrepackSwitchColumn: isMobile || shouldHidePrepackSwitch,
        uniquePrepacks,
        variantsWithDisabledPrepacks,
        variantsWithEnforcedPrepacks,
        visiblePrepacksLength,
      }}
    >
      {children}
    </MatrixPrepackContext.Provider>
  );
};
