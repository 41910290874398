import * as amplitude from '@amplitude/analytics-browser';

import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';

export const initAmplitude = () => {
  const apiKey = window.__SHOWROOM_SETTINGS__.AMPLITUDE_API_KEY;

  if (!isDefined(apiKey) || isEmpty(apiKey)) {
    return;
  }

  amplitude.init(apiKey, {
    appVersion: window.__SHOWROOM_SETTINGS__.APP_VERSION,
    defaultTracking: true,
  });
};
