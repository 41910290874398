import { Id, Selections } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { fetchHomePageByBuyerRequest, getBuyerFromCurrentOrder, getHomepageByBuyerId, getUserNavigation } from '../../../ducks';
import { getPagesListForOrderShare, getShareOrderPath } from '../../../logic/pages';
import { createAbsoluteUrl } from '../../../utils/createAbsoluteUrl';
import { getIsEmailValid } from '../../../utils/getIsEmailValid';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isEmpty } from '../../../utils/isEmpty';
import { Input } from '../../various/Fields/Input';
import { Option } from '../../various/Fields/Select';
import { AutocompleteField, FieldWrapper, Form, FormButtons, FormFieldset, FormGrid } from '../../various/Form';
import Icon, { IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';

import styles from './SelectionShareModal.module.scss';

interface FormData {
  emails: string[];
  page: string;
}

interface Props {
  selectionId: Id;
  isPending: boolean;
  onCancel: () => void;
  onSubmit: (payload: Selections.Share) => void;
}

export const SelectionShareForm = ({ selectionId, isPending, onCancel, onSubmit }: Props) => {
  const dispatch = useDispatch();

  const navigationItems = useSelector(getUserNavigation);
  const currentOrderBuyer = useSelector(getBuyerFromCurrentOrder);
  const homepageSlug = useSelector(getHomepageByBuyerId(currentOrderBuyer.buyer));

  const { t } = useTranslation(['common', 'checkout', 'validation', 'products', 'selections']);
  const { trackEvent } = useAmplitude();

  const defaultValues: FormData = {
    emails: !isEmpty(currentOrderBuyer.email) ? [currentOrderBuyer.email] : [],
    page: 'orders',
  };

  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    emails: yup.array(yup.string().defined().email()).min(1, t('validation:provide_min_one_email_hint')).defined(),
    page: yup.string().required(),
  });

  const formMethods = useValidatedForm<FormData>({ defaultValues, validationSchema });
  const page = formMethods.watch('page');

  const basePages = [
    {
      isHomepage: false,
      label: t('products:all_products'),
      url: 'products',
    },
    {
      isHomepage: false,
      label: t('selections:my_selection'),
      url: 'orders',
    },
    {
      isHomepage: false,
      label: t('common:checkout'),
      url: 'checkout',
    },
  ];

  const pagesList = getPagesListForOrderShare(navigationItems, homepageSlug, basePages);
  const sharePath = getShareOrderPath(page);

  const handleSharePathCopy = () => {
    trackEvent({ name: 'shareSelection.input.shareablelink.click' });
  };

  React.useEffect(() => {
    dispatch(fetchHomePageByBuyerRequest(currentOrderBuyer.buyer));
  }, [currentOrderBuyer, dispatch]);

  const handleSubmit = ({ emails }: FormData) => {
    onSubmit({ emails, path: sharePath });
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <p className={styles.text}>{t('checkout:share_selection_info_text')}</p>
      <FormFieldset isDisabled={isPending}>
        <FormGrid>
          <AutocompleteField name="page" isRequired label={t('checkout:direct_to_page')}>
            {pagesList.map(({ label, url, isHomepage }) => (
              <Option key={label} value={url}>
                {label}
                {isHomepage && <Icon className={styles.homeIcon} type={IconType.Home} />}
              </Option>
            ))}
          </AutocompleteField>
          <FieldWrapper label={t('checkout:shareable_link')}>
            <Input
              value={createAbsoluteUrl([sharePath, selectionId])}
              size="large"
              isReadonly
              isCopyable
              onFocus={event => event.target.select()}
              onCopy={handleSharePathCopy}
            />
          </FieldWrapper>
          <AutocompleteField
            name="emails"
            inputMode="email"
            isRequired
            allowCustomValues
            validateCustomValue={getIsEmailValid}
            label={t('checkout:email_to')}
            placeholder={t('checkout:add_email_address')}
            showArrow={false}
          />
        </FormGrid>
        <FormButtons showDivider>
          <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" isLoading={isPending}>
            {t('common:send')}
          </Button>
        </FormButtons>
      </FormFieldset>
    </Form>
  );
};
