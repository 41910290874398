import cx from 'classnames';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { getDeliveryWindowById } from '../../../../ducks';
import { useIsInProductDetailsModal } from '../../../../utils/hooks/useIsInProductDetailsModal';
import { useIsScrollingDown } from '../../../../utils/hooks/useIsScrollingDown';
import { isDefined } from '../../../../utils/is';
import { DelwinExpiredTooltip } from '../../../various/DelwinExpiredTooltip';
import { DelwinNameAndDate } from '../../../various/DelwinNameAndDate';
import { MatrixDeliveryWindowContext, MatrixEtaContext } from '../context';

import styles from './MatrixHeader.module.scss';
import { MatrixHeaderEtaActions } from './MatrixHeaderEtaActions';

const HEADER_HEIGHT = 55;
const TOP_BAR_HEIGHT = 45;
const ROOT_MARGIN_Y_IN_PDM = 24;

interface Props {
  shouldShowTooltip?: boolean;
}

export const MatrixHeader = ({ shouldShowTooltip = false }: Props) => {
  const { shouldShowEtaActions } = React.useContext(MatrixEtaContext);
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);

  const isScrollingDown = useIsScrollingDown({ isEnabled: shouldShowEtaActions });
  const isInProductDetailsModal = useIsInProductDetailsModal();
  const deliveryWindowSelector = React.useMemo(() => getDeliveryWindowById(deliveryWindowId), [deliveryWindowId]);
  const deliveryWindow = useSelector(deliveryWindowSelector);

  const rootMarginY = isScrollingDown ? TOP_BAR_HEIGHT : TOP_BAR_HEIGHT + HEADER_HEIGHT;

  const [ref, isInView, scrollEntry] = useInView({
    rootMargin: isInProductDetailsModal ? `${ROOT_MARGIN_Y_IN_PDM}px 0px` : `${-rootMarginY - 1}px 0px`,
    skip: !shouldShowEtaActions,
    threshold: 1,
  });

  const classNames = cx(styles.wrapper, {
    [styles.sticky]: shouldShowEtaActions,
    [styles.inPdm]: isInProductDetailsModal,
  });

  const borderStyle = React.useMemo(() => {
    const offsetLeft = scrollEntry?.boundingClientRect.left ?? 0;

    return offsetLeft > 0 ? { left: -offsetLeft } : undefined;
  }, [scrollEntry]);

  return (
    <div ref={ref} className={classNames}>
      {shouldShowEtaActions && !isInView && <span className={styles.border} style={borderStyle} />}
      {shouldShowEtaActions && <MatrixHeaderEtaActions />}
      <div className={styles.delwin}>
        {shouldShowTooltip && <DelwinExpiredTooltip className={styles.delwinTooltip} />}
        {isDefined(deliveryWindow) && <DelwinNameAndDate deliveryWindow={deliveryWindow} />}
      </div>
    </div>
  );
};
