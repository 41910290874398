import { Product } from '@typings';
import React from 'react';

import { useIsOverflowing } from '../../../../../utils/hooks/useIsOverflowing';
import { isDefined } from '../../../../../utils/is';
import { MatrixSettingsContext } from '../../../../products/ProductMatrix/context';
import { MaybeTooltip } from '../../../MaybeTooltip';

import styles from './VariantInfo.module.scss';

interface Props {
  variant: Product.Standard;
}

export const VariantName = ({ variant }: Props) => {
  const ref = React.useRef(null);
  const { productConfiguration } = React.useContext(MatrixSettingsContext);
  const isOverflowing = useIsOverflowing(ref);

  if (isDefined(productConfiguration)) {
    return null;
  }

  return (
    <MaybeTooltip content={isOverflowing ? variant.variantName : undefined}>
      <div className={styles.variantName}>
        <div ref={ref} className={styles.variantNameContent}>
          {variant.variantName}
        </div>
      </div>
    </MaybeTooltip>
  );
};
