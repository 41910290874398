import { Id, ItemEtasList, Position } from '@typings';
import cx from 'classnames';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import { ProductsEtaContext } from '../../../../components/products/ProductsEta';
import { getScannedBarcode, pushEvent, resetScannedBarcode } from '../../../../ducks';
import { getNumericValue } from '../../../../logic/validation';
import { BarcodeScannerTrackingEvent } from '../../../../utils/analytics/events';
import { useMatrixNavigation } from '../../../../utils/hooks/matrix/useMatrixNavigation';
import { isDefined } from '../../../../utils/is';
import { isDeleteKey, isNumberKey, Key } from '../../../../utils/keys';
import { getParsedPastedData, getQuantityInputValues } from '../../../../utils/matrix';
import { ProductDetailsModalContext } from '../../../products/product-details/ProductDetailsModal';
import {
  MatrixBulkEditingContext,
  MatrixEtaContext,
  MatrixNavigationContext,
  MatrixSettingsContext,
} from '../../../products/ProductMatrix/context';
import { QuantityCellInfo } from '../MatrixContents/QuantityCellInfo';

import styles from './MatrixCells.module.scss';
import { RegularBodyCell } from './RegularBodyCell';

interface Props {
  value: number;
  quantityBefore?: number;
  onBlur: (itemId: Id, value: number) => void;
  isDisabled?: boolean;
  isPrepack?: boolean;
  itemEtas?: ItemEtasList;
  color?: string | undefined;
  itemId: Id | undefined;
  itemEan?: string;
  inputValidator?: (value: number) => number;
  defaultPlaceholder: string;
  position: Position;
  isDistributionPreview?: boolean;
}

export const QuantityInputCell = (props: Props) => {
  const {
    value,
    onBlur,
    isDisabled,
    isPrepack,
    itemId,
    itemEan,
    quantityBefore,
    defaultPlaceholder,
    inputValidator,
    position,
    itemEtas,
    isDistributionPreview = false,
  } = props;
  const dispatch = useDispatch();
  const scannedBarcode = useSelector(getScannedBarcode);

  const [localValue, setLocalValue] = React.useState(value);
  const [isLocalEditing, setIsLocalEditing] = React.useState(false);

  const { resetSelectedPosition } = React.useContext(MatrixNavigationContext);
  const { isReadOnly, isLookbook } = React.useContext(MatrixSettingsContext);
  const { bulkValue, isBulkEditing, setBulkValue } = React.useContext(MatrixBulkEditingContext);
  const { legendColorPerEta } = React.useContext(ProductsEtaContext);
  const { getShouldHighlightItemEta } = React.useContext(MatrixEtaContext);
  const { isInDetailsModal } = React.useContext(ProductDetailsModalContext);

  const { isFocused, isSelected, setFocusableElement, isFocusTarget } = useMatrixNavigation(position);
  const canApplyBulkValue = isDefined(bulkValue) && isSelected && !isDisabled && !isPrepack;
  const setValue = isBulkEditing ? setBulkValue : setLocalValue;
  const { displayValue, placeholderValue, spacerValue } = getQuantityInputValues({
    defaultPlaceholder,
    isDistributionPreview,
    isReadOnly,
    localValue,
    savedValue: value,
  });

  React.useLayoutEffect(() => {
    if (isDefined(value)) {
      setLocalValue(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (isBulkEditing && canApplyBulkValue) {
      setLocalValue(bulkValue);
    }
  }, [bulkValue, canApplyBulkValue, isBulkEditing]);

  React.useEffect(() => {
    if (isBulkEditing) {
      setIsLocalEditing(false);
    }
  }, [isBulkEditing]);

  const shouldSendTrackingEvent = scannedBarcode === itemEan && isInDetailsModal;

  const handleBlur = React.useCallback(() => {
    if (!isDefined(itemId) || localValue === value) {
      return;
    }

    const validatedQuantity = isDefined(inputValidator) ? inputValidator(localValue) : localValue;

    if (shouldSendTrackingEvent) {
      dispatch(pushEvent({ event: BarcodeScannerTrackingEvent.SIZE_ADDED }));
      dispatch(resetScannedBarcode());
    }
    setBulkValue(null);
    setLocalValue(validatedQuantity);
    onBlur(itemId, validatedQuantity);
  }, [itemId, localValue, value, inputValidator, shouldSendTrackingEvent, setBulkValue, onBlur, dispatch]);

  React.useEffect(() => {
    if (!isSelected) {
      handleBlur();
    }
  }, [isSelected]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = getNumericValue(event.target.value);
      setValue(inputValue);
    },
    [setValue],
  );

  const handleNumericKey = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isNumberKey(event.key) || isLocalEditing) {
        return;
      }

      event.preventDefault();
      setValue(parseInt(event.key, 10));
      setIsLocalEditing(true);
    },
    [isLocalEditing, setValue],
  );

  const handleEnterKey = React.useCallback(() => {
    if (!isLocalEditing) {
      return;
    }

    resetSelectedPosition();
    setIsLocalEditing(false);
    handleBlur();
  }, [handleBlur, isLocalEditing, resetSelectedPosition]);

  const handleDeleteKey = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (isLocalEditing) {
        event.stopPropagation();
      }
    },
    [isLocalEditing],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const isEditKey = isNumberKey(event.key) || isDeleteKey(event.key);

      if (isEditKey && isPrepack && isBulkEditing) {
        event.preventDefault();
        resetSelectedPosition();
        setIsLocalEditing(true);

        return;
      }

      const handlers: Record<string, Maybe<(event: React.KeyboardEvent) => void>> = {
        [Key.ENTER]: handleEnterKey,
        [Key.DELETE]: handleDeleteKey,
        [Key.BACKSPACE]: handleDeleteKey,
      };

      const handler = handlers[event.code] ?? handleNumericKey;
      handler(event);
    },
    [isPrepack, isBulkEditing, resetSelectedPosition, handleNumericKey, handleEnterKey, handleDeleteKey],
  );

  const handleInViewChange = React.useCallback(
    (isInView: boolean) => {
      if (!isInView) {
        handleBlur();
      }
    },
    [handleBlur],
  );

  const enableLocalEditing = React.useCallback(() => {
    setIsLocalEditing(true);
  }, []);

  const handleCopy = (event: React.ClipboardEvent) => {
    if (isBulkEditing) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const pasteData = getParsedPastedData(event.clipboardData);
    const isRangeData = pasteData.flat().length > 1;

    if (isRangeData || !isLocalEditing) {
      event.preventDefault();

      return;
    }

    event.stopPropagation();
  };

  const getFocusProps = (hasFocus: boolean) => (hasFocus ? { ref: setFocusableElement } : {});

  const inputClassNames = cx(styles.quantityInput, {
    [styles.readOnly]: isReadOnly || isLookbook,
  });
  const isHighlighted = getShouldHighlightItemEta(itemEtas);
  const isInputDisabled = isDisabled || isReadOnly || isLookbook;
  const cellProps = getFocusProps(isInputDisabled);
  const inputProps = getFocusProps(!isInputDisabled);
  const isDistributionPreviewReduced = isDistributionPreview && isDefined(quantityBefore) && quantityBefore > value;
  const classNames = cx(styles.quantityCell, {
    [styles.highlighted]: isHighlighted,
    [styles.distributionPreview]: isDistributionPreview,
    [styles.distributionPreviewReduced]: isDistributionPreviewReduced,
  });
  const firstEta = itemEtas?.[0];
  const etaColor = isDefined(firstEta) ? legendColorPerEta[firstEta] : undefined;

  return (
    <RegularBodyCell
      className={classNames}
      color={etaColor}
      isDisabled={isDisabled}
      isSelected={isFocused || isSelected}
      dataTestId={`matrixQtyInputCell-${itemId}`}
      isFocusTarget={isInputDisabled && isFocusTarget}
      {...cellProps}
    >
      <QuantityCellInfo
        color={etaColor}
        quantityBefore={quantityBefore}
        value={value}
        itemEtas={itemEtas}
        isDistributionPreview={isDistributionPreview}
      >
        <InView as="div" rootMargin="-10px 0px" onChange={handleInViewChange}>
          <input
            className={inputClassNames}
            value={displayValue}
            placeholder={placeholderValue}
            onChange={handleChange}
            disabled={isInputDisabled}
            onKeyDown={handleKeyDown}
            role="spinbutton"
            onCopy={handleCopy}
            onPaste={handlePaste}
            onFocus={enableLocalEditing}
            onClick={enableLocalEditing}
            onTouchStart={enableLocalEditing}
            pattern="[0-9]*"
            inputMode="numeric"
            data-focus-target={!isInputDisabled && isFocusTarget}
            {...inputProps}
          />
        </InView>
        <span className={styles.quantitySpacer}>{spacerValue}</span>
      </QuantityCellInfo>
    </RegularBodyCell>
  );
};
