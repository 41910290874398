import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getIsAppReady, initHotjar } from '../ducks';

import { Header } from './header/Header';
import { CreateOrderModal } from './orders/CreateOrderModal';
import { EditOrderModal } from './orders/EditOrderModal';
import { SelectionOverviewModal } from './orders/SelectionOverviewModal';
import { SelectionShareModal } from './orders/SelectionShareModal';
import { ProductDetailsModal } from './products/product-details/ProductDetailsModal';
import { MatrixHelpModal } from './products/ProductMatrix/MatrixHelp';
import { ConfirmationModal } from './various/ConfirmationModal';
import DefaultLoader from './various/loaders/DefaultLoader';
import { OnboardingNotifications } from './various/OnboardingNotifications';
import styles from './App.module.scss';

interface Props {
  hideHeader?: boolean;
}

export const App = ({ children, hideHeader }: React.WithChildren<Props>) => {
  const isAppReady = useSelector(getIsAppReady);
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  React.useEffect(() => {
    dispatch(initHotjar());
  }, []);

  if (!isAppReady) {
    return <DefaultLoader overlay />;
  }

  return (
    <div className={styles.mainWrapper}>
      {!hideHeader && <Header />}
      <CreateOrderModal />
      <ProductDetailsModal />
      <EditOrderModal />
      <SelectionShareModal />
      <MatrixHelpModal />
      <SelectionOverviewModal />
      <ConfirmationModal />
      <OnboardingNotifications />
      {children}
    </div>
  );
};
