import React from 'react';

import {
  MatrixPrepackContext,
  MatrixQuantitiesContext,
  MatrixTotalsContext,
  useMatrixDistributionContext,
} from '../../../components/products/ProductMatrix/context';
import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';

import { useMatrixLayout } from './useMatrixLayout';

export const useGetAllCellValuesMap = () => {
  const { cellQuantitiesMap } = React.useContext(MatrixQuantitiesContext);
  const { prepacksMap, visiblePrepacksLength } = React.useContext(MatrixPrepackContext);
  const { shouldHideDistributionColumn } = useMatrixDistributionContext();
  const { totalsPerRow, columnQuantitiesSum, totalQuantitiesSum, totalPricesSum } = React.useContext(MatrixTotalsContext);

  const { isTwoDimensional } = useMatrixLayout();
  const prepacksOffsetPlaceholder: string[] = [...Array(visiblePrepacksLength)].fill('');

  const getPrepacksRowValues = React.useCallback(
    (variantId: string) => {
      const prepacks = prepacksMap[variantId]?.prepacks ?? [];
      const prepacksPlaceholder: number[] = [...Array(visiblePrepacksLength)].fill(0);

      if (isEmpty(prepacks)) {
        return prepacksPlaceholder;
      }

      return prepacks.reduce((acc: number[], prepack) => [...acc, prepack.quantity], []);
    },
    [prepacksMap, visiblePrepacksLength],
  );

  const distributionPlaceholder = React.useMemo(() => (shouldHideDistributionColumn ? [] : ['']), [shouldHideDistributionColumn]);

  const cellValues = React.useMemo(() => {
    return cellQuantitiesMap.map((row, index) => {
      const isFirstRow = index === 0;
      const prepacksValues =
        isTwoDimensional && !isFirstRow ? prepacksOffsetPlaceholder : getPrepacksRowValues(isDefined(row[0]) ? row[0].variantId : '');
      const inputValues = row.reduce((acc: number[], cell) => [...acc, cell.quantity], []);
      const totals = totalsPerRow[index];

      return [...prepacksValues, ...distributionPlaceholder, ...inputValues, totals?.quantity, totals?.price];
    });
  }, [cellQuantitiesMap, distributionPlaceholder, getPrepacksRowValues, isTwoDimensional, prepacksOffsetPlaceholder, totalsPerRow]);

  const footerTotals = React.useMemo(() => {
    return [
      ...prepacksOffsetPlaceholder,
      ...distributionPlaceholder,
      ...columnQuantitiesSum.map(([_, quantity]) => quantity),
      totalQuantitiesSum,
      totalPricesSum,
    ];
  }, [prepacksOffsetPlaceholder, distributionPlaceholder, columnQuantitiesSum, totalQuantitiesSum, totalPricesSum]);

  return [...cellValues, footerTotals];
};
