import React from 'react';

import { getVariantsSizes } from '../../../../logic/products';
import { MatrixVariantsContext } from '../../../products/ProductMatrix/context';
import { RegularHeaderCell } from '../MatrixCells/RegularHeaderCell';

export const InputHeadCells = () => {
  const { variants } = React.useContext(MatrixVariantsContext);
  const sizes = getVariantsSizes(variants);

  return (
    <>
      {sizes.map(size => (
        <RegularHeaderCell key={size}>{size}</RegularHeaderCell>
      ))}
    </>
  );
};
