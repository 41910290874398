import { showroomApi } from './api';

export const login = async (email: string, password: string) => {
  return showroomApi.QUERY_POST('/login')({
    data: {
      email,
      password,
    },
  });
};

export const logout = async () => {
  return showroomApi.POST('/logout')({ data: {} });
};
