import { Id, Matrices, Product } from '@typings';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { removePrepacksRequest, setItemsRequest } from '../../../../ducks';
import { getExtraSizeInfo } from '../../../../logic/sizeCharts';
import { getUnitSwitcherName } from '../../../../logic/unitsSwitch';
import { paths } from '../../../../paths';
import { useSelectedUnit } from '../../../../utils/hooks/unitSwitcher/useSelectedUnit';
import { useAmplitude } from '../../../../utils/hooks/useAmplitude';
import { useIsInProductDetailsModal } from '../../../../utils/hooks/useIsInProductDetailsModal';
import { useStockFormatter } from '../../../../utils/hooks/useStockFormatter';
import { useUnitsValidator } from '../../../../utils/hooks/useUnitsValidator';
import { isDefined } from '../../../../utils/is';
import {
  MatrixDeliveryWindowContext,
  MatrixPrepackContext,
  MatrixSettingsContext,
  MatrixVariantsContext,
  useMatrixDistributionContext,
} from '../../../products/ProductMatrix/context';
import { ProductsEtaContext } from '../../../products/ProductsEta';
import { NoStockCell } from '../MatrixCells/NoStockCell';
import { QuantityInputCell } from '../MatrixCells/QuantityInputCell';

interface Props {
  rowQuantities: Matrices.CellQuantities[];
  variant: Product.Standard;
  hasAppliedPrepacks: boolean;
  isCancelled: boolean;
  rowIndex: number;
}

export const InputBodyCells = ({ rowQuantities, variant, hasAppliedPrepacks, isCancelled, rowIndex }: Props) => {
  const dispatch = useDispatch();
  const unitsValidator = useUnitsValidator();
  const stockFormatter = useStockFormatter();
  const isInProductDetailsModal = useIsInProductDetailsModal();
  const isMySelectionPage = useMatch(paths.ORDER_DETAILS)?.pattern.end ?? false;
  const { trackEvent } = useAmplitude();
  const { activePrepacks, visiblePrepacksLength } = React.useContext(MatrixPrepackContext);
  const { stockPerItem, availableItemIds } = React.useContext(MatrixVariantsContext);
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);
  const { allItemsEtaPerDelwin } = React.useContext(ProductsEtaContext);
  const { distributionsOffset, distributionItemsIds } = useMatrixDistributionContext();
  const { isOrderClosed } = React.useContext(MatrixSettingsContext);
  const { itemQuantityMinimum, itemQuantityMultipleOf } = variant;
  const switchName = getUnitSwitcherName(variant.tableMappings ?? {});
  const selectedUnit = useSelectedUnit({ switchName });

  const isDisabled = !!activePrepacks[variant.variant] || isCancelled;

  const handleQuantityUpdate = React.useCallback(
    (itemId: Id, value: number) => {
      if (isInProductDetailsModal) {
        trackEvent({ name: 'pdm.input.qty.filledin' });
      }

      if (isMySelectionPage) {
        trackEvent({ name: 'selection.input.qty.filledin' });
      }

      if (hasAppliedPrepacks) {
        dispatch(
          removePrepacksRequest({
            data: { deliveryWindow: deliveryWindowId, variant: variant.variant },
            product: variant.product,
          }),
        );
      }

      dispatch(
        setItemsRequest({
          deliveryWindow: deliveryWindowId,
          item: itemId,
          product: variant.product,
          quantity: value,
          ...getExtraSizeInfo(variant, itemId, selectedUnit),
        }),
      );
    },
    [isInProductDetailsModal, isMySelectionPage, hasAppliedPrepacks, dispatch, deliveryWindowId, variant, selectedUnit, trackEvent],
  );

  return (
    <>
      {rowQuantities.map((cell, idx) => {
        const { itemId, quantity, quantityBefore, ean } = cell;
        const itemEtas = allItemsEtaPerDelwin[deliveryWindowId]?.[itemId ?? ''];
        const stock = isDefined(itemId) ? stockPerItem[itemId] ?? 0 : 0;
        const formattedStock = stockFormatter(stock);
        const position = {
          x: visiblePrepacksLength + distributionsOffset + idx,
          y: rowIndex,
        };

        const itemEtasList = typeof itemEtas === 'string' ? [itemEtas] : itemEtas;
        const itemHasPreview = isDefined(itemId) && distributionItemsIds.includes(itemId);

        if ((!isDefined(itemId) || !availableItemIds.includes(itemId)) && !isOrderClosed && !itemHasPreview) {
          return <NoStockCell key={idx} itemEtas={itemEtasList} isDisabled={isDisabled} position={position} />;
        }

        return (
          <QuantityInputCell
            isDistributionPreview={itemHasPreview}
            position={position}
            itemId={itemId}
            itemEan={ean}
            key={itemId}
            isDisabled={isDisabled}
            value={quantity}
            itemEtas={itemEtasList}
            onBlur={handleQuantityUpdate}
            defaultPlaceholder={formattedStock}
            quantityBefore={quantityBefore}
            inputValidator={unitsValidator({
              minimumQuantity: itemQuantityMinimum,
              multipleOf: itemQuantityMultipleOf,
              stock,
            })}
          />
        );
      })}
    </>
  );
};
