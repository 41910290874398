import { Matrices } from '@typings';
import React from 'react';

import { MatrixPrepackContext, MatrixTotalsContext, useMatrixDistributionContext } from '../../../products/ProductMatrix/context';
import { RowSummaryCell } from '../MatrixCells';
import { TotalsSummary } from '../MatrixContents/TotalsSummary';

interface Props {
  rowIndex: number;
  rowQuantities: Matrices.CellQuantities[];
}

export const TotalsSummaryBodyCell = ({ rowIndex, rowQuantities }: Props) => {
  const { totalsPerRow } = React.useContext(MatrixTotalsContext);
  const { visiblePrepacksLength } = React.useContext(MatrixPrepackContext);
  const { distributionsOffset } = useMatrixDistributionContext();

  const rowTotal = totalsPerRow[rowIndex]?.price ?? 0;
  const inputColumnsCount = rowQuantities.length;

  const position = {
    x: visiblePrepacksLength + distributionsOffset + inputColumnsCount,
    y: rowIndex,
  };

  const totalQuantitiesSum = rowQuantities.reduce((acc, row) => {
    return acc + row.quantity;
  }, 0);

  return (
    <RowSummaryCell position={position}>
      <TotalsSummary totalPrice={rowTotal} totalQuantity={totalQuantitiesSum} />
    </RowSummaryCell>
  );
};
