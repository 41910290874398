import { Position } from '@typings';
import React from 'react';

import { MatrixNavigationContext } from '../../../components/products/ProductMatrix/context';
import { isDefined } from '../../is';
import { useEventListener } from '../useEventListener';

export const useMatrixFocus = <T extends HTMLElement>(cellPosition: Position, ref: React.RefObject<T>) => {
  const { focusedPosition, setFocusedPosition, hasActiveElement } = React.useContext(MatrixNavigationContext);

  const cellMatchesCurrentPosition = focusedPosition.x === cellPosition.x && focusedPosition.y === cellPosition.y;
  const isFocused = cellMatchesCurrentPosition && hasActiveElement;

  const handleFocus = React.useCallback(() => {
    setFocusedPosition(cellPosition);
  }, [setFocusedPosition, cellPosition]);

  React.useEffect(() => {
    if (!isFocused || !isDefined(ref.current) || document.activeElement === ref.current) {
      return;
    }

    ref.current.focus();

    if (ref.current instanceof HTMLInputElement) {
      ref.current.select();
    }

    const elementPosition = ref.current.getBoundingClientRect();
    const elementFromPoint = document.elementFromPoint(elementPosition.x, elementPosition.y);
    const isCoveredByCell = isDefined(elementFromPoint) && elementFromPoint.tagName === 'TH' && elementFromPoint !== ref.current;

    if (isCoveredByCell) {
      ref.current.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [isFocused, ref.current]);

  useEventListener({
    element: ref,
    eventName: 'focus',
    handler: handleFocus,
  });

  return {
    isFocusTarget: cellMatchesCurrentPosition,
    isFocused,
  };
};
