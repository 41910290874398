import { Email } from '@typings';
import { useTranslation } from 'react-i18next';

import { FormButtons } from '../../various/Form';
import { Link } from '../../various/Link';
import { Button } from '../../various/NewButton';

import styles from './SelectionShareModal.module.scss';

interface Props {
  emails: Email[];
  onConfirm: () => void;
}

export const SelectionShareSuccess = ({ emails, onConfirm }: Props) => {
  const { t } = useTranslation(['checkout', 'common']);

  return (
    <>
      <p className={styles.text}>{t('checkout:share_selection_success')}</p>
      <ul className={styles.emailList} data-sentry-mask>
        {emails.map(({ email, link }) => (
          <li key={email}>
            <Link variant="black" external target="_blank" to={link}>
              {email}
            </Link>
          </li>
        ))}
      </ul>
      <FormButtons>
        <Button size="large" onClick={onConfirm}>
          {t('common:done')}
        </Button>
      </FormButtons>
    </>
  );
};
