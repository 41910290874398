import { ItemEtasList } from '@typings';
import cx from 'classnames';
import React from 'react';

import { MatrixEtaContext } from '../../../../../components/products/ProductMatrix/context';
import { ETA_TOOLTIP_MOUSE_ENTER_DELAY } from '../../../../../constants/delays';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import Icon, { IconType } from '../../../Icon';
import { MaybeTooltip } from '../../../MaybeTooltip';
import { Trend } from '../../../Trend/Trend';

import { EtaTooltipContent } from './EtaTooltipContent';
import styles from './QuantityCellInfo.module.scss';
import { TrendTooltipLine } from './TrendTooltipLine';

interface Props {
  value: number;
  quantityBefore: number | undefined;
  color?: string | undefined;
  itemEtas?: ItemEtasList;
  isDistributionPreview: boolean;
}

export const QuantityCellInfo = ({
  children,
  value,
  quantityBefore,
  color,
  itemEtas,
  isDistributionPreview,
}: React.WithChildren<Props>) => {
  const { isEtaVisible, getShouldHighlightItemEta } = React.useContext(MatrixEtaContext);
  const isQuantityChanged = isDefined(quantityBefore) && quantityBefore !== value;

  const removedQuantity = isDefined(quantityBefore) && quantityBefore > value ? quantityBefore - value : undefined;

  const addedQuantity = isDefined(quantityBefore) && quantityBefore < value ? value - quantityBefore : undefined;

  const shouldShowItemEtas = isDefined(itemEtas) && !isEmpty(itemEtas);
  const isHighlighted = getShouldHighlightItemEta(itemEtas);

  const tooltipContent =
    isQuantityChanged || shouldShowItemEtas ?
      <>
        {shouldShowItemEtas && <EtaTooltipContent itemEtas={itemEtas} />}
        {isQuantityChanged && <TrendTooltipLine removedQuantity={removedQuantity} addedQuantity={addedQuantity} />}
      </>
    : undefined;

  if (isDistributionPreview) {
    return (
      <>
        <div className={styles.wrapper}>
          {children}
          {isQuantityChanged && (
            <div className={styles.quantityChangeWrapper}>
              <Trend className={styles.trendIcon} type={removedQuantity ? 'down' : 'up'} />
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <MaybeTooltip content={tooltipContent} mouseEnterDelay={ETA_TOOLTIP_MOUSE_ENTER_DELAY}>
      <div className={styles.wrapper}>
        {children}
        {isQuantityChanged && (
          <div className={styles.quantityChangeWrapper}>
            <span>{quantityBefore}</span>
            <Trend className={styles.trendIcon} type={removedQuantity ? 'down' : 'up'} />
          </div>
        )}
        {shouldShowItemEtas && (
          <span
            className={cx(styles.etaIcon, {
              [styles.withColor]: isEtaVisible,
              [styles.withBackground]: isEtaVisible && !isHighlighted,
            })}
            style={{ color }}
          >
            <Icon type={IconType.Hourglass} />
          </span>
        )}
      </div>
    </MaybeTooltip>
  );
};
