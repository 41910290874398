import { Preset, Product, UnitsDistribution } from '@typings';
import React from 'react';
import { useForm } from 'react-hook-form';

import { EMPTY_ARRAY } from '../../../../constants/empty';
import { createDistributionSet, distributeToIdeal, getDistributionByItem } from '../../../../logic/unitsDistribution';
import { useMatrixPresets } from '../../../../utils/hooks/matrix/useMatrixPresets';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import { MatrixPrepackContext } from './MatrixPrepackContext';
import { MatrixSettingsContext } from './MatrixSettingsContext';
import { MatrixVariantsContext } from './MatrixVariantsContext';

interface Context {
  distributionsMap: Record<Product.Id, Preset[]>;
  distributionsOffset: number;
  shouldHideDistribution: boolean;
  shouldHideDistributionColumn: boolean;
  variantsWithDisabledDistributions: Product.Id[];
  quantitiesFormMethods: ReturnType<typeof useForm<UnitsDistribution.QuantitiesFormData>>;
  modeFormMethods: ReturnType<typeof useForm<UnitsDistribution.ModeFormData>>;
  distributionPreview: Record<string, Preset.Item>;
  setDistributionVariant: (variant: Product.Standard | undefined) => void;
  distributionItemsIds: string[];
  isDistributionPopupOpen: boolean;
  openDistributionPopup: (variant: Product.Standard) => void;
  closeDistributionPopup: () => void;
  distributionVariant: Product.Standard | undefined;
  distributionResult: Record<string, Preset.Item>;
  setDistributionResult: (result: Record<string, Preset.Item>) => void;
  distributionStage: UnitsDistribution.Stage;
  setDistributionStage: (stage: UnitsDistribution.Stage) => void;
}

const MatrixDistributionContext = React.createContext<Context | null>(null);

export const useMatrixDistributionContext = (): Context => {
  const context = React.useContext(MatrixDistributionContext);

  if (!isDefined(context)) {
    throw new Error('MatrixDistributionContext can not be used outside the scope of MatrixDistributionContextProvider');
  }

  return context;
};

export const MatrixDistributionContextProvider = ({ children }: React.WithChildren) => {
  const { cancelledVariantsIds } = React.useContext(MatrixVariantsContext);
  const { activePrepacks } = React.useContext(MatrixPrepackContext);
  const { isMobile } = React.useContext(MatrixSettingsContext);
  const { distributionsMap, shouldHideDistribution, productDistributions } = useMatrixPresets();

  const [isDistributionPopupOpen, setIsDistributionPopupOpen] = React.useState(false);
  const [distributionVariant, setDistributionVariant] = React.useState<Product.Standard | undefined>(undefined);
  const distributionItems = distributionVariant?.items ?? EMPTY_ARRAY;

  const [distributionResult, setDistributionResult] = React.useState<Record<string, Preset.Item>>({});
  const [distributionPreview, setDistributionPreview] = React.useState<Record<string, Preset.Item>>({});
  const [distributionStage, setDistributionStage] = React.useState<UnitsDistribution.Stage>('preview');

  const defaultQuantitiesValues = Object.keys(productDistributions).reduce((acc, id) => {
    return {
      ...acc,
      [id]: 0,
    };
  }, {});

  const quantitiesFormMethods = useForm<UnitsDistribution.QuantitiesFormData>({ defaultValues: defaultQuantitiesValues });
  const modeFormMethods = useForm<UnitsDistribution.ModeFormData>({ defaultValues: { mode: 'maxAvailable' } });
  const { watch } = quantitiesFormMethods;

  const openDistributionPopup = React.useCallback((variant: Product.Standard) => {
    setIsDistributionPopupOpen(true);
    setDistributionVariant(variant);
  }, []);

  const closeDistributionPopup = React.useCallback(() => {
    quantitiesFormMethods.reset();
    modeFormMethods.reset();
    setIsDistributionPopupOpen(false);
    setDistributionVariant(undefined);
    setDistributionPreview({});
    setDistributionResult({});
    setDistributionStage('preview');
  }, [modeFormMethods, quantitiesFormMethods]);

  const calculateDistributionPreview = React.useCallback(
    (distributionsRequestedValues: UnitsDistribution.QuantitiesFormData) => {
      const distributions = createDistributionSet(distributionsRequestedValues, productDistributions);

      const result = distributeToIdeal({ distributions });
      const distributionByItem = getDistributionByItem(result, distributionVariant?.items ?? EMPTY_ARRAY);

      setDistributionPreview(distributionByItem);
    },
    [distributionVariant?.items, productDistributions],
  );

  React.useEffect(() => {
    const subscription = watch(calculateDistributionPreview);

    return () => subscription.unsubscribe();
  }, [watch, calculateDistributionPreview]);

  const variantsWithDisabledDistributions = React.useMemo(
    () =>
      Object.entries(distributionsMap)
        .filter(
          ([variantId, distribution]) => isEmpty(distribution) || activePrepacks[variantId] || cancelledVariantsIds.includes(variantId),
        )
        .map(([key]) => key),
    [activePrepacks, cancelledVariantsIds, distributionsMap],
  );

  const shouldHideDistributionColumn = shouldHideDistribution || isMobile;
  const distributionsOffset = shouldHideDistributionColumn ? 0 : 1;

  return (
    <MatrixDistributionContext.Provider
      value={{
        closeDistributionPopup,
        distributionItemsIds: distributionItems.map(({ item }) => item),
        distributionPreview,
        distributionResult,
        distributionStage,
        distributionVariant,
        distributionsMap,
        distributionsOffset,
        isDistributionPopupOpen,
        modeFormMethods,
        openDistributionPopup,
        quantitiesFormMethods,
        setDistributionResult,
        setDistributionStage,
        setDistributionVariant,
        shouldHideDistribution,
        shouldHideDistributionColumn,
        variantsWithDisabledDistributions,
      }}
    >
      {children}
    </MatrixDistributionContext.Provider>
  );
};
