import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '../../../components/various/Icon';
import { addToast, dismissToast } from '../../../components/various/Toasts';
import { getDownloadLinesheetGeneratedEvent } from '../../../utils/analytics/events';
import { generateLinesheet } from '../../documentsRepository';
import { downloadURI } from '../../download';
import { pushEventGTM } from '../../gtm';

const NOTIFICATION_KEY = 'generateLinesheetNotification';

export const useLinesheetDownload = () => {
  const { t } = useTranslation(['linesheets']);

  const closeToast = React.useCallback(() => {
    dismissToast(NOTIFICATION_KEY);
  }, []);

  const mutationFn = async (values: Requests.Linesheet) => {
    const eventPayload = {
      showCustomerPrice: values.showCustomerPriceFlag,
      showStock: values.showStock,
      skipOutOfStock: values.skipOutOfStock,
      template: values.template,
    };

    pushEventGTM(getDownloadLinesheetGeneratedEvent(eventPayload));

    return generateLinesheet(values);
  };

  const handleMutate = () => {
    addToast(t('linesheets:generating_linesheet'), {
      description: t('linesheets:generate_linesheet_hint', { orderNumberText: '' }),
      duration: 0,
      icon: <Icon type={IconType.Spinner} />,
      id: NOTIFICATION_KEY,
      onClose: closeToast,
      position: 'bottom-left',
    });
  };

  const handleError = () => {
    addToast(t('linesheets:generate_linesheet_fail'), {
      description: t('linesheets:generate_linesheet_fail_hint', { orderNumberText: '' }),
      duration: 0,
      icon: <Icon type={IconType.Alert} />,
      id: NOTIFICATION_KEY,
      onClose: closeToast,
      position: 'bottom-left',
    });
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onMutate: handleMutate,
    onSuccess: ({ url }) => {
      downloadURI(url, 'linesheet');
      closeToast();
    },
  });
};
