import { Id, Product } from '@typings';
import { groupWith } from 'ramda';
import React from 'react';

import { getIsTwoDimensional } from '../../../logic/sizeCharts';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { getHasValidConfigurator } from '../../../utils/matrix';
import { report } from '../../../utils/monitoring';

import {
  MatrixColumnsContextProvider,
  MatrixDeliveryWindowContextProvider,
  MatrixEtaContextProvider,
  MatrixSettingsContext,
  MatrixVariantsContext,
  MatrixVariantsContextProvider,
} from './context';
import { InvalidMatrixInfo } from './InvalidMatrixInfo';
import { MatrixLazyRender } from './MatrixLazyRender';
import { ProductMatrixSectionGroup } from './ProductMatrixSectionGroup';

interface Props {
  deliveryWindowId: Id;
  variants: (Product.Standard | Product.Full)[];
  isFirstEtaDelwin?: boolean;
  isFirstDelWin: boolean;
}

const ProductMatrixSectionComponent = () => {
  const { productConfiguration } = React.useContext(MatrixSettingsContext);
  const { variants } = React.useContext(MatrixVariantsContext);

  const hasInvalidConfigurator = isDefined(productConfiguration) && !getHasValidConfigurator(productConfiguration);

  React.useEffect(() => {
    if (hasInvalidConfigurator) {
      const productId = variants[0]?.centraProduct;
      report(`Product configurator matrix for centra product ${productId} could not be rendered: Secondary attribute is missing.`);
    }
  }, []);

  if (isEmpty(variants)) {
    return null;
  }

  return hasInvalidConfigurator ? <InvalidMatrixInfo /> : <MatrixLazyRender />;
};

export const ProductMatrixSection = ({ deliveryWindowId, variants, isFirstEtaDelwin, isFirstDelWin }: Props) => {
  const { isMobile } = React.useContext(MatrixSettingsContext);

  if (isEmpty(variants)) {
    return null;
  }

  const variantGroups = groupWith(
    (a, b) => (isMobile ? a.variant === b.variant : !getIsTwoDimensional(a.itemTable) && !getIsTwoDimensional(b.itemTable)),
    variants,
  );

  return (
    <MatrixDeliveryWindowContextProvider deliveryWindowId={deliveryWindowId} isFirstDelWin={isFirstDelWin}>
      <MatrixEtaContextProvider variants={variants} isFirstEtaDelwin={isFirstEtaDelwin}>
        <ProductMatrixSectionGroup>
          {variantGroups.map(variantGroup => (
            <MatrixColumnsContextProvider key={variantGroup[0]?.variant}>
              <MatrixVariantsContextProvider variants={variantGroup}>
                <ProductMatrixSectionComponent />
              </MatrixVariantsContextProvider>
            </MatrixColumnsContextProvider>
          ))}
        </ProductMatrixSectionGroup>
      </MatrixEtaContextProvider>
    </MatrixDeliveryWindowContextProvider>
  );
};
