import { Id } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer } from '../../../../ducks';

interface Props {
  deliveryWindowId: Id;
  isFirstDelWin: boolean;
}

interface Context {
  deliveryWindowId: Id;
  isDeliveryWindowExpired: boolean;
  isFirstDelWin: boolean;
}

const initialContext: Context = {
  deliveryWindowId: '',
  isDeliveryWindowExpired: false,
  isFirstDelWin: false,
};

export const MatrixDeliveryWindowContext = React.createContext<Context>(initialContext);

export const MatrixDeliveryWindowContextProvider = (props: React.WithChildren<Props>) => {
  const { children, deliveryWindowId, isFirstDelWin } = props;
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const isDeliveryWindowExpired = !activeDeliveryWindowsIds.includes(deliveryWindowId);

  return (
    <MatrixDeliveryWindowContext.Provider
      value={{
        deliveryWindowId,
        isDeliveryWindowExpired,
        isFirstDelWin,
      }}
    >
      {children}
    </MatrixDeliveryWindowContext.Provider>
  );
};
