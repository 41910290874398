import React from 'react';

import {
  MatrixPrepackContext,
  MatrixQuantitiesContext,
  MatrixTotalsContext,
  useMatrixDistributionContext,
} from '../../../products/ProductMatrix/context';
import { RowSummaryCell } from '../MatrixCells';
import { TotalsSummary } from '../MatrixContents/TotalsSummary';

export const TotalsSummaryFooterCell = () => {
  const { totalPricesSum, totalQuantitiesSum } = React.useContext(MatrixTotalsContext);
  const { cellQuantitiesMap } = React.useContext(MatrixQuantitiesContext);
  const { visiblePrepacksLength } = React.useContext(MatrixPrepackContext);
  const { distributionsOffset } = useMatrixDistributionContext();

  const inputColumnsCount = cellQuantitiesMap[0]?.length ?? 0;
  const position = {
    x: visiblePrepacksLength + distributionsOffset + inputColumnsCount,
    y: cellQuantitiesMap.length,
  };

  return (
    <RowSummaryCell position={position}>
      <TotalsSummary totalPrice={totalPricesSum} totalQuantity={totalQuantitiesSum} isHighlighted />
    </RowSummaryCell>
  );
};
