import React from 'react';

import { MatrixSettingsContext, useMatrixDistributionContext } from '../../../products/ProductMatrix/context';
import { RegularHeaderCell } from '../MatrixCells/RegularHeaderCell';

import { InputHeadCells } from './InputHeadCells';
import { PrepacksHeadCells } from './PrepacksHeadCells';
import { TotalsSummaryHeadCell } from './TotalsSummaryHeadCell';
import { TwoDimensionalHeadCell } from './TwoDimensionalHeadCell';
import { VariantHeadCells } from './VariantHeadCells';

export const MatrixHead = React.memo(() => {
  const { isMobile } = React.useContext(MatrixSettingsContext);
  const { shouldHideDistributionColumn } = useMatrixDistributionContext();

  return (
    <thead>
      <tr data-testid="matrixHeadRow">
        {!isMobile && (
          <>
            <VariantHeadCells />
            <th />
          </>
        )}
        <PrepacksHeadCells />
        {!shouldHideDistributionColumn && <RegularHeaderCell />}
        <TwoDimensionalHeadCell />
        <InputHeadCells />
        <TotalsSummaryHeadCell />
      </tr>
    </thead>
  );
});
