import React from 'react';
import { useSelector } from 'react-redux';

import { getOptimisticUpdatesMultipleProducts, getOrderProductsFilteredBy } from '../../../../ducks';
import { getMultivariantRelatedProductIds, getVariantsSizes } from '../../../../logic/products';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import { MatrixDeliveryWindowContext } from './MatrixDeliveryWindowContext';
import { useMatrixDistributionContext } from './MatrixDistributionContext';
import { MatrixQuantitiesContext } from './MatrixQuantitiesContext';
import { MatrixSettingsContext } from './MatrixSettingsContext';
import { MatrixVariantsContext } from './MatrixVariantsContext';

interface Context {
  totalQuantitiesSum: number;
  totalPricesSum: number;
  totalsPerRow: { price: number; quantity: number }[];
  columnQuantitiesSum: (string | number)[][];
}

const initialContext: Context = {
  columnQuantitiesSum: [],
  totalPricesSum: 0,
  totalQuantitiesSum: 0,
  totalsPerRow: [],
};

export const MatrixTotalsContext = React.createContext<Context>(initialContext);

export const MatrixTotalsContextProvider = ({ children }: React.WithChildren) => {
  const { showCancelled } = React.useContext(MatrixSettingsContext);
  const { variants, pricePerVariant } = React.useContext(MatrixVariantsContext);
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);
  const { cellQuantitiesMap } = React.useContext(MatrixQuantitiesContext);
  const { distributionPreview, distributionResult } = useMatrixDistributionContext();

  const orderProducts = useSelector(getOrderProductsFilteredBy(showCancelled));
  const [firstVariant] = variants;
  const productId = firstVariant?.product;

  const allProductIds = [productId, ...getMultivariantRelatedProductIds(firstVariant)].filter(isDefined);
  const optimisticUpdates = useSelector(getOptimisticUpdatesMultipleProducts(allProductIds));
  const sizes = getVariantsSizes(variants);

  const orderedVariants = React.useMemo(() => {
    return orderProducts.filter(product => allProductIds.includes(product.product) && product.deliveryWindow === deliveryWindowId);
  }, [orderProducts, productId, deliveryWindowId]);

  const columnQuantitiesSum = React.useMemo(() => {
    return sizes.map((size, idx) => {
      const sum = cellQuantitiesMap.reduce((acc, row) => {
        return acc + (row[idx]?.quantity ?? 0);
      }, 0);

      return [size, sum];
    });
  }, [sizes, cellQuantitiesMap]);

  const totalQuantitiesSum = React.useMemo(
    () =>
      cellQuantitiesMap.flat().reduce((acc, row) => {
        return acc + row.quantity;
      }, 0),
    [cellQuantitiesMap],
  );

  const totalsPerRow = cellQuantitiesMap.map(row => {
    const variantId = row[0]?.variantId;

    if (!isDefined(variantId)) {
      return { price: 0, quantity: 0 };
    }

    const variantPrice = pricePerVariant[variantId]?.priceAsNumber ?? 0;

    const rowItems = row.map(item => item.itemId);
    const orderedItemsWithPrices = orderedVariants
      .filter(variant => variant.variant === variantId)
      .flatMap(variant =>
        variant.items.map(item => ({
          ...item,
          priceEachAsNumber: variant.priceEachAsNumber,
        })),
      );

    return rowItems.reduce(
      (acc, itemId) => {
        const optimisticKey = `${deliveryWindowId}-${itemId}`;
        const optimisticQuantity = optimisticUpdates[optimisticKey];
        const distributionQuantitySource = !isEmpty(distributionResult) ? distributionResult : distributionPreview;
        const distributionQuantity = distributionQuantitySource[itemId ?? '']?.quantity ?? 0;

        const orderedItem = orderedItemsWithPrices.find(item => item.item === itemId);

        if (isDefined(orderedItem)) {
          const quantity =
            isDefined(optimisticQuantity) ? optimisticQuantity + distributionQuantity : orderedItem.quantity + distributionQuantity;

          return {
            price: acc.price + quantity * orderedItem.priceEachAsNumber,
            quantity: acc.quantity + quantity,
          };
        }

        const quantity = (optimisticQuantity ?? 0) + distributionQuantity;

        return {
          price: acc.price + quantity * variantPrice,
          quantity: acc.quantity + quantity,
        };
      },
      { price: 0, quantity: 0 },
    );
  });

  const totalPricesSum = React.useMemo(() => totalsPerRow.reduce((acc, row) => acc + row.price, 0), [totalsPerRow]);

  return (
    <MatrixTotalsContext.Provider
      value={{
        columnQuantitiesSum,
        totalPricesSum,
        totalQuantitiesSum,
        totalsPerRow,
      }}
    >
      {children}
    </MatrixTotalsContext.Provider>
  );
};
