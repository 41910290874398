import { SwatchData } from '@typings';
import cx from 'classnames';

import { isDefined } from '../../../../utils/is';
import Icon, { IconType } from '../../../various/Icon';
import { Swatch } from '../../../various/Swatch/Swatch';
import { TextEllipsis } from '../../../various/TextEllipsis';

import styles from './ClearFilterButton.module.scss';

interface Props {
  name: string | JSX.Element;
  onClick: () => void;
  className?: string;
  swatchData?: SwatchData;
  appearance?: 'clearAll' | 'clearSearchFilter';
}

export const ClearFilterButton = ({ onClick, name, className, swatchData, appearance }: Props) => {
  return (
    <button
      className={cx(styles.clearButton, className, {
        [styles.clearAll]: appearance === 'clearAll',
        [styles.clearSearchFilter]: appearance === 'clearSearchFilter',
      })}
      onClick={onClick}
    >
      {isDefined(swatchData) && <Swatch swatchData={swatchData} />}
      <TextEllipsis>{name}</TextEllipsis>
      <Icon className={styles.crossIcon} type={IconType.Cross} />
    </button>
  );
};
