import { Position, Product } from '@typings';
import React from 'react';

import { useMatrixNavigation } from '../../../../utils/hooks/matrix/useMatrixNavigation';
import { isDefined } from '../../../../utils/is';
import { MatrixDeliveryWindowContext, useMatrixDistributionContext } from '../../../products/ProductMatrix/context';
import { PresetsPopover } from '../MatrixContents/PresetsPopover';

import { RegularBodyCell } from './RegularBodyCell';

interface Props {
  position: Position;
  rowSpan?: number;
  rowIndex: number;
  variant: Product.Standard;
}

export const DistributionCell = ({ position, rowSpan, rowIndex, variant }: Props) => {
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);
  const { distributionsMap, shouldHideDistributionColumn, variantsWithDisabledDistributions } = useMatrixDistributionContext();

  const { isFocused, isSelected, setFocusableElement, isFocusTarget } = useMatrixNavigation(position);

  const is2DSpacingCell = isDefined(rowSpan) && rowIndex === 1;
  const is2DSkipableRow = isDefined(rowSpan) && rowIndex > 1;

  if (shouldHideDistributionColumn || is2DSkipableRow) {
    return null;
  }

  if (is2DSpacingCell) {
    return <td rowSpan={rowSpan} />;
  }

  const distributions = distributionsMap[variant.variant] ?? [];
  const isDistributionDisabled = variantsWithDisabledDistributions.includes(variant.variant);

  const cellProps = isDistributionDisabled ? { isFocusTarget, ref: setFocusableElement } : {};

  const presetsProps = !isDistributionDisabled && {
    isFocusTarget,
    ref: setFocusableElement as unknown as React.RefObject<HTMLButtonElement>,
  };

  return (
    <RegularBodyCell isDisabled={isDistributionDisabled} isSelected={isFocused || isSelected} {...cellProps}>
      <PresetsPopover
        variant={variant}
        deliveryWindowId={deliveryWindowId}
        disabled={isDistributionDisabled}
        distributions={distributions}
        shouldShowLabel={false}
        {...presetsProps}
      />
    </RegularBodyCell>
  );
};
