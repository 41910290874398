import { Toast } from '@typings';
import { useDispatch } from 'react-redux';

import { isDefined } from '../../../utils/is';
import Button from '../Button';

interface Props {
  handleClose: () => void;
  action: Toast.Action;
}

export const ToastActionButton = ({ action, handleClose }: Props) => {
  const dispatch = useDispatch();

  const handleActionClick = () => {
    if (!isDefined(action.action)) {
      return;
    }

    handleClose();
    action.onClick?.();
    dispatch(action.action);
  };

  return (
    <Button onClick={handleActionClick} variant={['button', 'extraSmall', 'transparent']}>
      {action.label}
    </Button>
  );
};
