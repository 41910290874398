import React from 'react';

import {
  MatrixPrepackContext,
  MatrixQuantitiesContext,
  MatrixTotalsContext,
  useMatrixDistributionContext,
} from '../../../products/ProductMatrix/context';
import { ColumnSummaryCell } from '../MatrixCells';

export const InputFooterCells = () => {
  const { cellQuantitiesMap } = React.useContext(MatrixQuantitiesContext);
  const { visiblePrepacksLength } = React.useContext(MatrixPrepackContext);
  const { columnQuantitiesSum } = React.useContext(MatrixTotalsContext);
  const { distributionsOffset } = useMatrixDistributionContext();

  return (
    <>
      {columnQuantitiesSum.map(([size, quantity], idx) => {
        const position = {
          x: idx + visiblePrepacksLength + distributionsOffset,
          y: cellQuantitiesMap.length,
        };

        return (
          <ColumnSummaryCell key={size} position={position}>
            {quantity}
          </ColumnSummaryCell>
        );
      })}
    </>
  );
};
