import React from 'react';

import { isDefined } from '../../../../../utils/is';
import { PrepackSwitch } from '../../../../various/Matrix/MatrixContents/PrepackSwitch';
import { PresetsPopover } from '../../../../various/Matrix/MatrixContents/PresetsPopover';
import {
  MatrixDeliveryWindowContext,
  MatrixPrepackContext,
  MatrixSettingsContext,
  MatrixVariantsContext,
  useMatrixDistributionContext,
} from '../../context';

import styles from './DistributionControls.module.scss';

interface Props {
  isDistributionsLabelHidden: boolean;
}

export const DistributionControls = React.forwardRef<HTMLDivElement, Props>(({ isDistributionsLabelHidden }, ref) => {
  const { isMobile } = React.useContext(MatrixSettingsContext);
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);
  const { variants } = React.useContext(MatrixVariantsContext);
  const { shouldHidePrepackSwitch } = React.useContext(MatrixPrepackContext);
  const { distributionsMap, shouldHideDistribution, variantsWithDisabledDistributions } = useMatrixDistributionContext();

  const [firstVariant] = variants;

  if (!isDefined(firstVariant)) {
    return null;
  }

  const isDistributionDisabled = variantsWithDisabledDistributions.includes(firstVariant.variant);
  const distributions = distributionsMap[firstVariant.variant];

  if (!isMobile || (shouldHideDistribution && shouldHidePrepackSwitch) || !isDefined(distributions)) {
    return null;
  }

  return (
    <div className={styles.controls} ref={ref}>
      {!shouldHidePrepackSwitch && (
        <div className={styles.control}>
          <PrepackSwitch variantId={firstVariant.variant} />
        </div>
      )}
      {!shouldHideDistribution && (
        <div className={styles.control}>
          <PresetsPopover
            variant={firstVariant}
            deliveryWindowId={deliveryWindowId}
            disabled={isDistributionDisabled}
            distributions={distributions}
            shouldShowLabel={!isDistributionsLabelHidden}
          />
        </div>
      )}
    </div>
  );
});
