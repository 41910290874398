import { Dates, ItemEtasList, LegendItem, Product } from '@typings';
import React from 'react';
import { useHover, UseHoverProps } from 'react-laag';
import { useSelector } from 'react-redux';

import { getShouldShowEtaOnDesktop, getShouldShowEtaOnMobile } from '../../../../ducks';
import { useContainsActiveElement } from '../../../../utils/hooks/useContainsActiveElement';
import { useEtaLegend } from '../../../../utils/hooks/useEtaLegend';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { ProductDetailsModalContext } from '../../product-details/ProductDetailsModal';

import { MatrixDeliveryWindowContext } from './MatrixDeliveryWindowContext';
import { MatrixSettingsContext } from './MatrixSettingsContext';

interface Props {
  variants: (Product.Standard | Product.Full)[];
  isFirstEtaDelwin?: boolean;
}

interface Context {
  activeEta: Dates.DateAsSQLString | null;
  containerRef: React.RefObject<HTMLDivElement>;
  hasActiveEta: boolean;
  hoverProps: UseHoverProps | Empty.Object;
  legendItems: LegendItem<Dates.DateAsSQLString>[];
  setActiveEta: (label: Dates.DateAsSQLString | null) => void;
  getShouldHighlightItemEta: (itemEtas?: ItemEtasList) => boolean;
  isEtaVisible: boolean;
  shouldShowEtaActions: boolean;
  shouldShowEtaLegend: boolean;
}

const initialContext = {
  activeEta: null,
  containerRef: { current: null },
  getShouldHighlightItemEta: () => false,
  hasActiveEta: false,
  hoverProps: {},
  isEtaVisible: false,
  legendItems: [],
  setActiveEta: () => null,
  shouldShowEtaActions: false,
  shouldShowEtaLegend: false,
};

export const MatrixEtaContext = React.createContext<Context>(initialContext);

export const MatrixEtaContextProvider = React.memo(({ children, variants, isFirstEtaDelwin }: React.WithChildren<Props>) => {
  const shouldShowEtaOnMobile = useSelector(getShouldShowEtaOnMobile);
  const shouldShowEtaOnDesktop = useSelector(getShouldShowEtaOnDesktop);

  const { isMobile } = React.useContext(MatrixSettingsContext);
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);

  const { hasActiveElement, containerRef } = useContainsActiveElement<HTMLDivElement>();
  const { hasItemsWithEta, legendItems } = useEtaLegend(variants, deliveryWindowId);
  const [isOver, hoverProps] = useHover({ hideOnScroll: false });
  const { shouldScrollToEtaMatrix, setShouldScrollToEtaMatrix } = React.useContext(ProductDetailsModalContext);
  const [activeEta, setActiveEta] = React.useState<Dates.DateAsSQLString | null>(null);

  React.useEffect(() => {
    if (shouldScrollToEtaMatrix && hasItemsWithEta && isFirstEtaDelwin) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
      setShouldScrollToEtaMatrix(false);
    }
  }, [containerRef, hasItemsWithEta, isFirstEtaDelwin, setShouldScrollToEtaMatrix, shouldScrollToEtaMatrix]);

  const shouldShowEtaLegend = !isMobile && hasItemsWithEta;
  const shouldShowEtaActions = isMobile && hasItemsWithEta;

  const isEtaVisible = isMobile ? shouldShowEtaOnMobile : shouldShowEtaOnDesktop || isOver || hasActiveElement;
  const getShouldHighlightItemEta = React.useCallback(
    (itemEtas?: ItemEtasList) => {
      if (!isDefined(itemEtas) || isEmpty(itemEtas)) {
        return false;
      }

      const [earliestDate] = itemEtas;

      return earliestDate === activeEta;
    },
    [activeEta],
  );

  return (
    <MatrixEtaContext.Provider
      value={{
        activeEta,
        containerRef,
        getShouldHighlightItemEta,
        hasActiveEta: isDefined(activeEta),
        hoverProps: shouldShowEtaLegend ? hoverProps : {},
        isEtaVisible,
        legendItems,
        setActiveEta,
        shouldShowEtaActions,
        shouldShowEtaLegend,
      }}
    >
      {children}
    </MatrixEtaContext.Provider>
  );
});
