import { Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getImageForVariant } from '../../../../../ducks/helpers';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { MatrixSettingsContext } from '../../../../products/ProductMatrix/context';
import { CustomImage } from '../../../CustomImage';
import Icon, { IconType } from '../../../Icon/Icon';

import styles from './VariantImage.module.scss';

interface Props {
  variant: Product.Standard;
}

export const VariantImage = ({ variant }: Props) => {
  const { productConfiguration } = React.useContext(MatrixSettingsContext);
  const imageForVaiantSelector = React.useMemo(() => getImageForVariant(variant), [variant]);
  const image = useSelector(imageForVaiantSelector);

  if (isDefined(productConfiguration)) {
    return null;
  }

  const hasImage = !isEmpty(image.src);

  return (
    <div className={cx(styles.image, { [styles.placeholder]: !hasImage })}>
      {hasImage ?
        <CustomImage enlargeOnHover aspectRatio={1} src={image.src} />
      : <Icon type={IconType.ImagePlaceholder} size={32} />}
    </div>
  );
};
