import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchInvoicesRequest,
  fetchOrdersRequest,
  getCheckoutSplitData,
  getIsRequestingSplitOrder,
  getSplitOrderOriginalId,
  resetCheckoutSuccess,
  resetOrderData,
} from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { CheckoutSingleSuccess } from '../CheckoutSingleSuccess';
import { CheckoutSplitSuccess } from '../CheckoutSplitSuccess';

export const CheckoutSuccess = () => {
  const dispatch = useDispatch();
  const orderId = useParams<{ id?: string }>().id;
  const splitOrderOriginalId = useSelector(getSplitOrderOriginalId);
  const splitOrdersCount = useSelector(getCheckoutSplitData).orders.length;
  const isRequestingSplitOrder = useSelector(getIsRequestingSplitOrder);
  const isSplitOrder = splitOrdersCount > 0 && orderId === splitOrderOriginalId;

  React.useEffect(() => {
    if (isDefined(orderId)) {
      dispatch(fetchInvoicesRequest(orderId));
    }
    dispatch(fetchOrdersRequest());

    return () => {
      dispatch(resetOrderData());
      dispatch(resetCheckoutSuccess());
    };
  }, [dispatch, orderId]);

  return isSplitOrder || isRequestingSplitOrder ? <CheckoutSplitSuccess /> : <CheckoutSingleSuccess />;
};
