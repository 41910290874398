import { Prepack } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  MatrixDeliveryWindowContext,
  MatrixQuantitiesContext,
  MatrixVariantsContext,
} from '../../../components/products/ProductMatrix/context';
import { getPrepacksForVariants } from '../../../ducks';
import { getArePrepacksEnforced } from '../../../logic/presets';

export const usePrepacksMap = () => {
  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);
  const { availableItemIds, variants } = React.useContext(MatrixVariantsContext);
  const { prepacksQuantitiesInVariants } = React.useContext(MatrixQuantitiesContext);

  const prepacksForVariantsSelector = React.useMemo(() => getPrepacksForVariants(variants, deliveryWindowId), [deliveryWindowId, variants]);
  const prepacksForVariants = useSelector(prepacksForVariantsSelector);

  return React.useMemo(
    () =>
      variants.reduce<Prepack.Map>((acc, product) => {
        const variantId = product.variant;
        const variantPrepacks = prepacksForVariants[variantId];
        const prepackQuantities = prepacksQuantitiesInVariants[variantId] ?? {};

        const sizeAvailabilities = product.items.reduce<Record<string, boolean>>(
          (items, item) => ({
            ...items,
            [item.sizeId]: availableItemIds.includes(item.item),
          }),
          {},
        );

        const validPrepacks =
          variantPrepacks?.filter(prepack => prepack.items.every(item => item.quantity === 0 || sizeAvailabilities[item.sizeId])) ?? [];

        const validFullPrepacks = validPrepacks.map(prepack => ({
          ...prepack,
          quantity: prepackQuantities[prepack.id] ?? 0,
        }));

        const isEnforced = getArePrepacksEnforced(validFullPrepacks, product);

        return {
          ...acc,
          [variantId]: { isEnforced, prepacks: validFullPrepacks },
        };
      }, {}),
    [availableItemIds, prepacksForVariants, prepacksQuantitiesInVariants, variants],
  );
};
