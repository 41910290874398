import { Product } from '@typings';
import React from 'react';

import { useProductConfiguration } from '../../../../utils/hooks/matrix/useProductConfiguration';
import { isEmpty } from '../../../../utils/isEmpty';
import { AttributeName, AttributeValue } from '../../../various/Matrix/MatrixContents/Attribute';
import { VariantImage } from '../../../various/Matrix/MatrixContents/VariantImage';
import { VariantInfo } from '../../../various/Matrix/MatrixContents/VariantInfo';
import { MatrixSettingsContext } from '../context';

import styles from './MatrixVariantInfo.module.scss';

interface Props {
  variant: Product.Standard;
}

export const MatrixVariantInfo = ({ variant }: Props) => {
  const { productConfiguration } = React.useContext(MatrixSettingsContext);
  const { configurationAttributeTypes, configurationAttributeValuesPerVariant } = useProductConfiguration({
    productConfiguration,
    variants: [variant],
  });

  return (
    <>
      {!isEmpty(configurationAttributeTypes) && (
        <div className={styles.attributes}>
          {configurationAttributeTypes.map(({ key, name, attributesDetails }, index) => (
            <div key={key} className={styles.attribute}>
              <AttributeName name={name} attributesDetails={attributesDetails} />
              <AttributeValue value={configurationAttributeValuesPerVariant[0]?.[index] ?? ''} />
            </div>
          ))}
        </div>
      )}
      <div className={styles.variantContent}>
        <VariantImage variant={variant} />
        <VariantInfo variant={variant} />
      </div>
    </>
  );
};
