import React from 'react';

import { useMatrixLayout } from '../../../../utils/hooks/matrix/useMatrixLayout';
import { MatrixSettingsContext } from '../../../products/ProductMatrix/context';

import { InputFooterCells } from './InputFooterCells';
import { OffsetFooterCells } from './OffsetFooterCells';
import { TotalsSummaryFooterCell } from './TotalsSummaryFooterCell';

export const MatrixFooter = React.memo(() => {
  const { isLookbook } = React.useContext(MatrixSettingsContext);
  const { hasMultipleRows } = useMatrixLayout();

  if (isLookbook || !hasMultipleRows) {
    return null;
  }

  return (
    <tr>
      <OffsetFooterCells />
      <InputFooterCells />
      <TotalsSummaryFooterCell />
    </tr>
  );
});
