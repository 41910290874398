import { Linesheet } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { fetchDeliveryWindowsByBuyerIdRequest, getDeliveryWindowsByBuyerId, getIsDeliveryWindowsLoading } from '../../../ducks';
import { getAccountNameWithId, getBuyerFullName } from '../../../logic/accounts';
import { getHumanTimePeriod } from '../../../logic/deliveryWindows';
import { getSortByTuple } from '../../../logic/documents';
import { useCollections } from '../../../services/hooks/collections/useCollections';
import { useLinesheetDownload } from '../../../services/hooks/linesheet/useLinesheetDownload';
import { useLinesheetTemplates } from '../../../services/hooks/linesheet/useLinesheetTemplates';
import { useUnits } from '../../../services/hooks/units/useUnits';
import { useBuyerSelect } from '../../../utils/hooks/selection/useBuyerSelect';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { getDeliveryWindowsSchema } from '../../../utils/schemas/getDeliveryWindowsSchema';
import { Option } from '../../various/Fields/Select';
import { AutocompleteField, Form, FormFieldset, FormGrid } from '../../various/Form';
import { NoLinesheetsMessage } from '../NoLinesheetsMessage';

import styles from './LinesheetGenerator.module.scss';
import { LinesheetGeneratorHeader } from './LinesheetGeneratorHeader';
import { LinesheetGeneratorOptions } from './LinesheetGeneratorOptions';
import { LinesheetGeneratorTemplates } from './LinesheetGeneratorTemplates';

const DEFAULT_TEMPLATE_ID = '1';

export const LinesheetGenerator = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buyers', 'linesheets', 'validation']);

  const { mutate, isPending } = useLinesheetDownload();
  const { data: linesheets, isLoading: isLoadingTemplates } = useLinesheetTemplates();
  const { data: units, isLoading: isLoadingUnits } = useUnits();
  const { options, isLoading: isLoadingBuyers, handleLoadMore, handleSearchChange } = useBuyerSelect();

  const templates = linesheets?.templates ?? {};
  const defaultLinesheetTemplate = templates[DEFAULT_TEMPLATE_ID] as Maybe<Linesheet.TemplateData>;

  const defaultValues: Linesheet.FormData = {
    attributes: defaultLinesheetTemplate?.attributes ?? [],
    buyer: null,
    collections: [],
    deliveryWindows: [],
    groupBy: defaultLinesheetTemplate?.groupBy ?? null,
    linesheetTemplate: DEFAULT_TEMPLATE_ID,
    showCustomerPrice: true,
    showStockValues: true,
    skipOutOfStock: false,
    sortBy: defaultLinesheetTemplate?.sortBy ?? null,
    thenSortBy: defaultLinesheetTemplate?.thenSortBy ?? 'none',
    units: [],
  };

  const validationSchema: yup.ObjectSchema<Pick<Linesheet.FormData, 'buyer' | 'deliveryWindows'>> = yup.object({
    buyer: yup.string().required(t('validation:select_buyer_hint')),
    deliveryWindows: getDeliveryWindowsSchema(),
  });

  const formMethods = useValidatedForm({ defaultValues, validationSchema });
  const { setValue, watch } = formMethods;

  const [buyerId, deliveryWindowIds, selectedTemplateId] = watch(['buyer', 'deliveryWindows', 'linesheetTemplate']);

  const deliveryWindowsSelector = React.useMemo(() => getDeliveryWindowsByBuyerId(buyerId), [buyerId]);
  const deliveryWindowsForBuyer = useSelector(deliveryWindowsSelector);
  const isLoadingDeliveryWindows = useSelector(getIsDeliveryWindowsLoading);

  const { data: collectionsData, isLoading: isLoadingCollections } = useCollections({ buyerId, deliveryWindowIds });

  const handleBuyerChange = (buyer: string) => {
    dispatch(fetchDeliveryWindowsByBuyerIdRequest({ buyerId: buyer }));
    setValue('deliveryWindows', []);
    setValue('collections', []);
  };

  const onDeliveryWindowsChange = () => {
    setValue('collections', []);
  };

  const handleSubmit = React.useCallback(
    (values: Linesheet.FormData) => {
      const { showCustomerPrice, showStockValues, linesheetTemplate, groupBy, sortBy, thenSortBy, attributes, ...rest } = values;

      mutate({
        showCustomerPriceFlag: showCustomerPrice,
        showStock: showStockValues,
        template: linesheetTemplate,
        templateDO: {
          groupBy,
          productAttributes: attributes,
          sortBy: getSortByTuple(sortBy, thenSortBy),
        },
        ...rest,
      });
    },
    [mutate],
  );

  if (isEmpty(templates) && !isLoadingTemplates) {
    return <NoLinesheetsMessage />;
  }

  return (
    <section>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <LinesheetGeneratorHeader isPending={isPending} />
        <FormFieldset isDisabled={isPending}>
          <FormGrid rowGap="large">
            <LinesheetGeneratorTemplates templates={templates} isLoading={isLoadingTemplates} />
            <AutocompleteField
              isRequired
              label={t('buyers:buyer_one')}
              isLoading={isLoadingBuyers}
              name="buyer"
              orientation="horizontal"
              placeholder={t('buyers:select_buyer')}
              description={t('linesheets:choose_buyer.hint')}
              onChange={handleBuyerChange}
              onSearchChange={handleSearchChange}
              onLoadMore={handleLoadMore}
            >
              {options.map(buyer => (
                <Option key={buyer.id} value={buyer.id}>
                  {getBuyerFullName(buyer)} <span className={styles.hint}>({getAccountNameWithId(buyer)})</span>
                </Option>
              ))}
            </AutocompleteField>
            <AutocompleteField
              isRequired
              isDisabled={!isDefined(buyerId)}
              isLoading={isLoadingDeliveryWindows}
              name="deliveryWindows"
              orientation="horizontal"
              description={t('linesheets:choose_collection.hint')}
              label={t('linesheets:choose_delwin.label')}
              placeholder={t('linesheets:choose_delwin.placeholder')}
              onChange={onDeliveryWindowsChange}
            >
              {deliveryWindowsForBuyer.map(delwin => (
                <Option key={delwin.deliveryWindow} value={delwin.deliveryWindow}>
                  {delwin.name} <span className={styles.hint}>({getHumanTimePeriod(delwin).deliveryPeriod})</span>
                </Option>
              ))}
            </AutocompleteField>
            <AutocompleteField
              isLoading={isLoadingCollections}
              label={t('linesheets:choose_collection.label')}
              name="collections"
              orientation="horizontal"
              description={t('linesheets:choose_collection.hint')}
              placeholder={t('linesheets:choose_collection.placeholder')}
              isDisabled={isEmpty(deliveryWindowIds)}
            >
              {collectionsData?.collections.map(({ name, collection }) => (
                <Option key={collection} value={collection}>
                  {name}
                </Option>
              ))}
            </AutocompleteField>
            <AutocompleteField
              isLoading={isLoadingUnits}
              label={t('linesheets:choose_units.label')}
              name="units"
              orientation="horizontal"
              description={t('linesheets:choose_units.hint')}
              placeholder={t('linesheets:choose_units.placeholder')}
            >
              {units?.map(unit => (
                <Option key={unit} value={unit}>
                  {unit}
                </Option>
              ))}
            </AutocompleteField>
            <LinesheetGeneratorOptions selectedTemplate={templates[selectedTemplateId]} />
          </FormGrid>
        </FormFieldset>
      </Form>
    </section>
  );
};
