import { Matrices, Position, Product } from '@typings';
import { isEmpty, pipe } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getScannedBarcode, pushEvent } from '../../../../ducks';
import { removePrepacksRequest, setMultipleItemsRequest, setPrepacksRequest } from '../../../../ducks/order';
import { calculateOptimisticItems, getPrepacksFromMap, getPrepacksRequestData, updatePrepackQuantity } from '../../../../logic/presets';
import { getExtraSizeInfo } from '../../../../logic/sizeCharts';
import { getUnitSwitcherName } from '../../../../logic/unitsSwitch';
import { BarcodeScannerTrackingEvent, MatrixTrackingEvent } from '../../../../utils/analytics/events';
import { useEventListener } from '../../../../utils/hooks';
import { useGetAllCellValuesMap } from '../../../../utils/hooks/matrix/useGetAllCellValuesMap';
import { useMatrixCopyHandler } from '../../../../utils/hooks/matrix/useMatrixCopyHandler';
import { useMatrixLayout } from '../../../../utils/hooks/matrix/useMatrixLayout';
import { useNavigationRange } from '../../../../utils/hooks/matrix/useNavigationRange';
import { useUnitSwitcher } from '../../../../utils/hooks/unitSwitcher/useUnitSwitcher';
import { useContainsActiveElement } from '../../../../utils/hooks/useContainsActiveElement';
import { useUnitsValidator } from '../../../../utils/hooks/useUnitsValidator';
import { isDefined, isNull } from '../../../../utils/is';
import { isArrowKey, isTabKey, Key } from '../../../../utils/keys';
import {
  areDeleteKeysPressed,
  exitMatrix,
  getIsAnyMatrixFocused,
  getNewEndFromPasteData,
  getNewLocationKeyboardNav,
  getParsedPastedData,
  getSelectedCells,
  jumpToMatrix,
  jumpToMatrixByTarget,
} from '../../../../utils/matrix';

import { MatrixDeliveryWindowContext } from './MatrixDeliveryWindowContext';
import { useMatrixDistributionContext } from './MatrixDistributionContext';
import { MatrixPrepackContext } from './MatrixPrepackContext';
import { MatrixQuantitiesContext } from './MatrixQuantitiesContext';
import { MatrixSettingsContext } from './MatrixSettingsContext';
import { MatrixVariantsContext } from './MatrixVariantsContext';

interface Context {
  focusedPosition: Position;
  hasActiveElement: boolean;
  isSelecting: boolean;
  resetSelectedPosition: () => void;
  setFocusedPosition: (position: Position) => void;
  setSelectedPosition: React.Dispatch<React.SetStateAction<Matrices.SelectedPosition>>;
  selectedPosition: Matrices.SelectedPosition;
}

const ZERO_POINT = { x: 0, y: 0 };
const FOCUS_CELL_DELAY = 500;

const initialContext: Context = {
  focusedPosition: ZERO_POINT,
  hasActiveElement: false,
  isSelecting: false,
  resetSelectedPosition: () => null,
  selectedPosition: { end: ZERO_POINT, start: ZERO_POINT },
  setFocusedPosition: (_: Position) => null,
  setSelectedPosition: (_: Matrices.SelectedPosition) => null,
};

export const MatrixNavigationContext = React.createContext<Context>(initialContext);

export const MatrixNavigationContextProvider = ({ children }: React.WithChildren) => {
  const { getColumnNavigationRange, getRowNavigationRange } = useNavigationRange();
  const initialPosition = {
    x: getRowNavigationRange(0).min,
    y: 0,
  };

  const dispatch = useDispatch();
  const [focusedPosition, setFocusedPosition] = React.useState<Position>(initialPosition);
  const { containerRef, hasActiveElement } = useContainsActiveElement<HTMLDivElement>();
  const scannedBarcode = useSelector(getScannedBarcode);
  const allCellValuesMap = useGetAllCellValuesMap();
  const unitsValidator = useUnitsValidator();
  const { selectedUnits } = useUnitSwitcher();
  const [selectedPosition, setSelectedPosition] = React.useState<Matrices.SelectedPosition>({
    end: initialPosition,
    start: initialPosition,
  });
  const [isSelecting, setIsSelecting] = React.useState(false);
  const { cellQuantitiesMap, prepacksQuantitiesInVariants } = React.useContext(MatrixQuantitiesContext);
  const { isReadOnly, isLookbook } = React.useContext(MatrixSettingsContext);
  const { activePrepacks, visiblePrepacksLength, prepacksMap, shouldHidePrepackSwitchColumn } = React.useContext(MatrixPrepackContext);
  const { distributionsOffset } = useMatrixDistributionContext();
  const { availableItemIds, cancelledVariantsIds, stockPerItem, variants } = React.useContext(MatrixVariantsContext);
  const { deliveryWindowId: deliveryWindow, isDeliveryWindowExpired, isFirstDelWin } = React.useContext(MatrixDeliveryWindowContext);
  const { isTwoDimensional } = useMatrixLayout();

  const { product } = variants[0] ?? {};

  React.useEffect(() => {
    if (isDefined(scannedBarcode) && !getIsAnyMatrixFocused()) {
      handleScannedBarcode();
    }
  }, []);

  React.useEffect(() => {
    if (!isSelecting && !hasActiveElement) {
      resetSelectedPosition();
    }
  }, [hasActiveElement]);

  React.useEffect(() => {
    resetSelectedPosition();
  }, [focusedPosition]);

  const resetSelectedPosition = React.useCallback(() => {
    setSelectedPosition({ end: focusedPosition, start: focusedPosition });
  }, [focusedPosition]);

  const areCellValuesEqual = (value: number, { x, y }: Position) => {
    return allCellValuesMap[y]?.[x] === value;
  };

  const updatePrepackCell = (position: Position, value: number) => {
    const { x, y } = position;
    const rowQuantities = cellQuantitiesMap[position.y];
    const variantId = rowQuantities?.[0]?.variantId;

    if (!isDefined(variantId) || areCellValuesEqual(value, position)) {
      return;
    }

    const isDisabled = getIsDisabledCell(variantId, true);

    if (isDisabled) {
      return;
    }

    const variantData = variants.find(variant => variant.variant === variantId);
    const switchName = getUnitSwitcherName(variantData?.tableMappings ?? {});
    const selectedUnit: Maybe<string> = selectedUnits[switchName];

    const prepackId = prepacksMap[variantId]?.prepacks[x]?.id;
    const variantItems = variants[y]?.items;

    if (!isDefined(prepackId) || !isDefined(variantItems) || !isDefined(product)) {
      return;
    }

    const prepacks = pipe(getPrepacksFromMap, updatePrepackQuantity(value, prepackId))(prepacksMap, variantId);
    const items = calculateOptimisticItems(variantItems, deliveryWindow)(prepacks);

    dispatch(
      setPrepacksRequest({
        data: {
          deliveryWindow,
          prepacks: getPrepacksRequestData(prepacks, selectedUnit),
          variant: variantId,
        },
        items,
        product,
      }),
    );
  };

  const getItemWithValidatedValue = ({
    itemId,
    variantData,
    value,
  }: {
    itemId: string;
    variantData: Product.Standard | Product.Full;
    value: number;
  }) => {
    const stock = stockPerItem[itemId] ?? 0;
    const switchName = getUnitSwitcherName(variantData.tableMappings ?? {});
    const selectedUnit: Maybe<string> = selectedUnits[switchName];

    const validatedValue = unitsValidator({
      minimumQuantity: variantData.itemQuantityMinimum,
      multipleOf: variantData.itemQuantityMultipleOf,
      stock,
    })(value);

    return {
      deliveryWindow,
      item: itemId,
      quantity: validatedValue,
      ...getExtraSizeInfo(variantData, itemId, selectedUnit),
    };
  };

  const updateCells = (data: { x: number; y: number; value?: number }[]) => {
    const items = data.reduce((acc, { x, y, value = 0 }) => {
      if (areCellValuesEqual(value, { x, y })) {
        return acc;
      }

      const cellData = cellQuantitiesMap[y]?.[x - visiblePrepacksLength - distributionsOffset];

      if (!isDefined(cellData) || !isDefined(cellData.itemId) || getIsDisabledCell(cellData.variantId)) {
        return acc;
      }

      const { itemId, variantId } = cellData;
      const variantData = variants.find(variant => variant.variant === variantId);

      if (!isDefined(variantData)) {
        return acc;
      }

      if (!availableItemIds.includes(itemId)) {
        return acc;
      }

      if (getHasAppliedPrepacks(variantId)) {
        removePrepacks(variantData);
      }

      return [...acc, getItemWithValidatedValue({ itemId, value, variantData })];
    }, []);

    if (isEmpty(items) || !isDefined(product)) {
      return;
    }

    dispatch(pushEvent({ event: MatrixTrackingEvent.PASTE_MULTI_SELECT_USED }));
    dispatch(setMultipleItemsRequest({ items, product }));
  };

  const getHasAppliedPrepacks = (variantId: string) => {
    const prepacksQuantities = prepacksQuantitiesInVariants[variantId];

    return isDefined(prepacksQuantities) && !isEmpty(Object.keys(prepacksQuantities));
  };

  const removePrepacks = (variantData: Product.Standard | Product.Full) => {
    if (!isDefined(product)) {
      return;
    }

    dispatch(
      removePrepacksRequest({
        data: { deliveryWindow, variant: variantData.variant },
        product,
      }),
    );
  };

  const handleDeleteKeys = () => {
    const selectedCells = getSelectedCells(selectedPosition.start, selectedPosition.end);

    if (selectedCells.length <= 1) {
      return;
    }

    updateCells(selectedCells);
  };

  const handleKeyboardNav = (event: React.KeyboardEvent) => {
    if (areDeleteKeysPressed(event.code)) {
      handleDeleteKeys();

      return;
    }

    const newLocation = getNewLocationKeyboardNav(event, focusedPosition);
    const isShiftArrowCombo = isArrowKey(event.key) && event.shiftKey;

    isShiftArrowCombo && dispatch(pushEvent({ event: MatrixTrackingEvent.MULTI_SELECT_USED_WITH_KEYBOARD }));

    if (!isDefined(newLocation) || isShiftArrowCombo) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setPosition(newLocation, event.key);
  };

  const handleScannedBarcode = () => {
    if (!isFirstDelWin) {
      return;
    }

    const cellToFocus = cellQuantitiesMap.flat().find(cell => {
      const { ean, itemId } = cell;
      const isItemAvailable = isDefined(itemId) && availableItemIds.includes(itemId);

      return ean === scannedBarcode && isItemAvailable;
    });

    if (!isDefined(cellToFocus)) {
      return;
    }

    const { variantId } = cellToFocus;

    const hasPrepack = !isEmpty(prepacksMap[variantId]?.prepacks ?? []) && !shouldHidePrepackSwitchColumn;
    const isPrepackEnforced = prepacksMap[variantId]?.isEnforced ?? false;

    const y = isTwoDimensional && isPrepackEnforced ? 0 : cellQuantitiesMap.findIndex(cells => cells.includes(cellToFocus));

    const yCellToFocus = cellQuantitiesMap[y]?.indexOf(cellToFocus) ?? -1;

    if (yCellToFocus === -1) {
      return;
    }

    const x = isPrepackEnforced ? distributionsOffset : yCellToFocus + Number(hasPrepack) + distributionsOffset;

    setFocusedPosition({ x, y });

    setTimeout(() => {
      jumpToMatrixByTarget(containerRef.current);
      dispatch(pushEvent({ event: BarcodeScannerTrackingEvent.SIZE_FOCUSED }));
    }, FOCUS_CELL_DELAY);
  };

  const setPosition = (newLocation: Position, key: string) => {
    const rowRange = getRowNavigationRange(newLocation.y);
    const columnRange = getColumnNavigationRange(newLocation.x);
    const nextY = focusedPosition.y + 1;
    const prevY = focusedPosition.y - 1;
    const nextRowStart = getRowNavigationRange(nextY).min;
    const prevRowEnd = getRowNavigationRange(prevY).max;

    if (newLocation.y > columnRange.max && key === Key.DOWN) {
      jumpToMatrix(1);

      return;
    }

    if (newLocation.y < columnRange.min) {
      jumpToMatrix(-1);

      return;
    }

    if (newLocation.x > rowRange.max) {
      if (nextY > getColumnNavigationRange(nextRowStart).max) {
        isTabKey(key) && exitMatrix(1);

        return;
      }

      setFocusedPosition({ x: nextRowStart, y: nextY });

      return;
    }

    if (newLocation.x < rowRange.min) {
      if (prevY < getColumnNavigationRange(prevRowEnd).min) {
        isTabKey(key) && exitMatrix(-1);

        return;
      }

      setFocusedPosition({ x: prevRowEnd, y: prevY });

      return;
    }

    setFocusedPosition(newLocation);
  };

  const getIsDisabledCell = React.useCallback(
    (variantId: string, isPrepackCell = false) => {
      const isCancelled = cancelledVariantsIds.includes(variantId);
      const activePrepack = activePrepacks[variantId];
      const isDisabled = isPrepackCell ? !activePrepack : activePrepack;

      return isDisabled || isCancelled || isDeliveryWindowExpired || isReadOnly || isLookbook;
    },
    [cancelledVariantsIds, activePrepacks, isDeliveryWindowExpired],
  );

  const getItemsFromPasteData = (pasteData: string[][]) => {
    const { start } = selectedPosition;

    return pasteData
      .map((row, pasteY) => {
        return row.reduce((acc, rowPastItem, pasteX) => {
          const value = parseInt(rowPastItem, 10);
          const numericValue = Number.isNaN(value) ? 0 : value;

          const newPosition = { x: start.x + pasteX, y: start.y + pasteY };
          const isPrepackCell = newPosition.x - visiblePrepacksLength < 0;

          if (isPrepackCell) {
            updatePrepackCell(newPosition, numericValue);

            return acc;
          }

          return [...acc, { value: numericValue, ...newPosition }];
        }, []);
      })
      .flat();
  };

  const handlePaste = ({ clipboardData }: React.ClipboardEvent) => {
    if (isNull(clipboardData) || isReadOnly || isLookbook) {
      return;
    }
    const { start } = selectedPosition;
    const pasteData = getParsedPastedData(clipboardData);
    const items = getItemsFromPasteData(pasteData);

    if (isEmpty(items)) {
      return;
    }

    updateCells(items);

    const end = getNewEndFromPasteData(pasteData, start);
    setSelectedPosition(prevPositionData => ({ ...prevPositionData, end }));
  };

  const handleMouseUp = React.useCallback(() => {
    setIsSelecting(false);
  }, []);

  const handleMouseDown = React.useCallback(() => {
    setIsSelecting(true);
  }, []);

  const handleCopy = useMatrixCopyHandler(selectedPosition, hasActiveElement);

  useEventListener({
    element: { current: document },
    eventName: 'mouseup',
    handler: handleMouseUp,
    isDisabled: !isSelecting,
  });

  return (
    <div
      ref={containerRef}
      onCopy={handleCopy}
      onPaste={handlePaste}
      {...(hasActiveElement && { onKeyDown: handleKeyboardNav })}
      {...(!isSelecting && { onMouseDown: handleMouseDown })}
    >
      <MatrixNavigationContext.Provider
        value={{
          focusedPosition,
          hasActiveElement,
          isSelecting,
          resetSelectedPosition,
          selectedPosition,
          setFocusedPosition,
          setSelectedPosition,
        }}
      >
        {children}
      </MatrixNavigationContext.Provider>
    </div>
  );
};
