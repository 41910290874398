import { BaseNumericPrices, DeliveryWindowSpecificPrice, Id, Product, Variants } from '@typings';
import { path, uniq } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAreBackordersEnabled, getOrderDetails } from '../../../../ducks';
import { getOrderedProducts, getStockForDeliveryWindow } from '../../../../logic/products';
import { getIsItemAvailable } from '../../../../utils/getIsItemAvailable';
import { useStockFormatter } from '../../../../utils/hooks/useStockFormatter';
import { isDefined } from '../../../../utils/is';

import { MatrixDeliveryWindowContext } from './MatrixDeliveryWindowContext';

interface Props {
  variants: (Product.Standard | Product.Full)[];
}

interface Context {
  availableItemIds: string[];
  variants: (Product.Standard | Product.Full)[];
  pricePerVariant: Record<string, BaseNumericPrices>;
  stockPerItem: Record<string, Variants.Stock>;
  cancelledVariantsIds: Id[];
}

const initialContext: Context = {
  availableItemIds: [],
  cancelledVariantsIds: [],
  pricePerVariant: {},
  stockPerItem: {},
  variants: [],
};

export const MatrixVariantsContext = React.createContext<Context>(initialContext);

export const MatrixVariantsContextProvider = (props: React.WithChildren<Props>) => {
  const { children, variants } = props;

  const { deliveryWindowId } = React.useContext(MatrixDeliveryWindowContext);
  const orderDetails = useSelector(getOrderDetails);
  const backordersEnabled = useSelector(getAreBackordersEnabled);

  const stockFormatter = useStockFormatter();

  const cancelledVariantsIds = React.useMemo(
    () =>
      uniq(
        orderDetails.order.products
          .filter(product => product.deliveryWindow === deliveryWindowId && product.isCancelled)
          .map(product => product.variant),
      ),
    [deliveryWindowId, orderDetails.order.products],
  );

  const getPriceForVariant = (variant: Product.Standard | Product.Full): BaseNumericPrices => {
    const orderedVariant = getOrderedProducts(orderDetails)
      .filter(product => product.deliveryWindow === deliveryWindowId)
      .find(product => product.variant === variant.variant);

    const deliveryWindowPath = ['deliveryWindowSpecificPrices', deliveryWindowId];
    const deliveryWindowSpecificPrices = path<DeliveryWindowSpecificPrice | undefined>(deliveryWindowPath, variant);

    const priceAsNumber = deliveryWindowSpecificPrices?.priceAsNumber ?? variant.priceAsNumber;
    const rrpAsNumber = orderedVariant?.rrpAsNumber ?? deliveryWindowSpecificPrices?.rrpAsNumber ?? variant.rrpAsNumber;
    const discountPercent = deliveryWindowSpecificPrices?.discountPercent ?? variant.discountPercent;
    const showAsOnSale = deliveryWindowSpecificPrices?.showAsOnSale ?? variant.showAsOnSale;

    return {
      discountPercent,
      priceAsNumber,
      rrpAsNumber,
      showAsOnSale,
    };
  };

  const pricePerVariant = variants.reduce<Record<string, BaseNumericPrices>>(
    (acc, variant) => ({
      ...acc,
      [variant.variant]: getPriceForVariant(variant),
    }),
    {},
  );

  const stockPerItem: Record<string, Variants.Stock> = variants.reduce((acc, variant) => {
    const items = variant.items.reduce((itemsAcc, { item, stockByDeliveryWindow }) => {
      return {
        ...itemsAcc,
        [item]: getStockForDeliveryWindow({ deliveryWindowId, stockByDeliveryWindow }),
      };
    }, {});

    return {
      ...acc,
      ...items,
    };
  }, {});

  const availableItemIds = variants.flatMap(variant =>
    variant.items
      .filter(item => {
        const stock = stockPerItem[item.item];
        const formattedStock = stockFormatter(stock);

        return (
          isDefined(stock) &&
          getIsItemAvailable({
            backordersEnabled,
            formattedItemQuantity: formattedStock,
            itemQuantityMinimum: variant.itemQuantityMinimum,
            itemQuantityMultipleOf: variant.itemQuantityMultipleOf,
            stock,
          })
        );
      })
      .map(item => item.item),
  );

  return (
    <MatrixVariantsContext.Provider
      value={{
        availableItemIds,
        cancelledVariantsIds,
        pricePerVariant,
        stockPerItem,
        variants,
      }}
    >
      {children}
    </MatrixVariantsContext.Provider>
  );
};
