import { useMutation } from '@tanstack/react-query';
import { Id, Selections } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { shareSelection } from '../../selectionRepository';

interface MutationVariables {
  selectionId: Id;
  data: Selections.Share;
}

export const useShareSelection = () => {
  const { t } = useTranslation(['checkout']);

  const mutationFn = async ({ selectionId, data }: MutationVariables) => {
    return shareSelection(selectionId, data);
  };

  const handleError = () => {
    addToast(t('checkout:share_selection_error'));
  };

  return useMutation({
    mutationFn,
    onError: handleError,
  });
};
